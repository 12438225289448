import React from "react";

import {useContextLayout} from "src/components/context/LayoutContext";
import SideBar from "./components/commons/sideBar/SideBar";
import FlexWrapper from "./components/commons/flexWrapper/FlexWrapper";

import { LayoutWrapper, Div } from "./Layout.style";

const Layout = ({ children, baseUrl }) => {
  const {isDeviceMobile, isMenuExpanded} = useContextLayout();

  return (
    <Div>
      <SideBar isDeviceMobile={isDeviceMobile} isOpen={isMenuExpanded} baseUrl={baseUrl}/>
      <LayoutWrapper hasMarginLeft={isMenuExpanded}>
        <FlexWrapper flexDirections="column" margins="1rem">
          {children}
        </FlexWrapper>
      </LayoutWrapper>
    </Div>
  );
};
export default Layout;
