import styled from "styled-components";

import { PrimaryColors, primaryColorsAlpha } from "../Theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ flexDirections }) => flexDirections};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : ""};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
  padding: ${({ paddings, withBackground }) => {
    if (paddings) return paddings;
    if (withBackground) return "1rem";
  }};
  margin: ${({ margins }) => (margins ? margins : "")};
  width: ${({ wrapperWidth }) => (wrapperWidth ? wrapperWidth : "")};
  height: ${({ wrapperHeight }) => wrapperHeight || ""};
  position: ${({ wrapperPosition }) =>
    wrapperPosition ? wrapperPosition : ""};
  top: ${({ wrapperTop }) => wrapperTop || ""};
  border: ${({ borders }) => borders};
  flex: ${({ flex }) => flex};
  gap: ${({ gap }) => gap};
  background-color: ${({ withBackground }) =>
    withBackground ? PrimaryColors.WhiteColor : ""};
  border-radius: ${({ withBackground }) => (withBackground ? "0.25rem" : "")};
  border: ${({ withBackground }) =>
    withBackground
      ? `0.0625rem solid ${primaryColorsAlpha.MainDarkColor08}`
      : ""};
`;
