import React, { useState, useEffect, createContext, useContext } from "react";
import Login from './Login';
import apiMobile from './apiMobile';
import './App.scss';
import MsgPage from "./MsgPage";

export const Context = createContext();
export const useSession = () => useContext(Context);

const Session = ({children}) => {

  let [session, update] = useState({
    step: 'INIT',
    user: null
  });

  const refreshUser = () => {
    update({ step:'LOADING', user: null });
    apiMobile.athlete.get('me')
    .then(user => {
      update({ step:'OK', user, msg: null });
    })
    .catch(e => {
      if (e.restCode === 'authRequired') return update({ step: 'OK', msg:"TODO" });
      if (e.restCode === 'authFailed') return update({ step: 'OK', msg:"TODO" });
      if (e.restCode === 'authExpired') return update({ step: 'OK', msg:"TODO" });
      if(e.restCode === 'authInvalid')	return update({ step:'OK', msg:"TODO"});
      console.error(e);
      update({ step: 'OK', user: null, error: e });
    });
  };

  useEffect(refreshUser, []);

  const signIn = ({ email, password }) => {
    update({ step: 'LOADING', user: null });
    apiMobile.session.create({ email, password })
    .then(() => {
      return apiMobile.athlete.get('me');
    })
    .then( user => {
      update({ step: 'OK', user, msg: null });
    })
    .catch(e => {
      console.error(e);
      update({ step: 'ERROR', error: e });
    });
  };

  const signOut = () => {
    update({ step: 'SIGNOUT', user:null });
    apiMobile.session.delete('mine')
    .then( () => refreshUser() );
  };

  if( session.step === 'INIT' || session.step === 'LOADING' ) {
    return <MsgPage type="LOADING" title="Chargement de la session..."/>;
  }

  if( session.step === 'SIGNOUT' ) {
    return <MsgPage type="LOADING" title="Déconnexion en cours..."/>;
  }

  if( session.step === 'ERROR' ) {
    return <MsgPage type="ERROR"/>;
  }

  if (!session.user) {
    return (
      <Login signIn={ signIn } />
    );
  }

  return (
    <Context.Provider value={[session, signOut]} >
      {children}
    </Context.Provider>
  );
};

export default Session;
