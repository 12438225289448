import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect, withRouter
} from "react-router-dom";

import './App.scss';
import LayoutContext, {useContextLayout} from "src/components/context/LayoutContext";
import Account from 'src/modules/Account/Account';
import Layout from './Layout';
import Session from './Session';
import VersionChecker from './VersionChecker';
import Header from "./components/header/Header";
import ClubSelect from "./ClubSelect";
import UserClubs from "./UserClubs";
import Planning from "./modules/planning/Planning";
import Booking from "src/modules/Booking/Booking";
import VideoContext from "./components/context/VideoContext";
import Streaming from "./modules/streaming/Streaming";
import Lives from "./modules/lives/Lives";
import LiveContext from "./components/context/LiveContext";

const App = () => {
  const timer = 60 * 5 * 1000; //5min in ms (s * nbMin * ms)
  return (
    <Router>
      <LayoutContext>
        <VersionChecker initial={ process.env.REACT_APP_VERSION } delay={timer}>
          <Session>
            <UserClubs>
              <Switch>
                <Route path='/club/:clubId' component={ClubApp} />
                <Redirect from='*' to='/club/load' />
              </Switch>
            </UserClubs>
          </Session>
        </VersionChecker>
      </LayoutContext>
    </Router>
  );
};

const ClubApp = ({match}) => {
  return (
    <React.Fragment>
      <ClubSelect clubId={match.params.clubId}>
        <Header baseUrl={match.url} />
        <VideoContext>
          <LiveContext>
            <Layout baseUrl={match.url}>
              <Switch>
                <Route exact path={`${match.url}/planning`} component={ Planning } />
                <Route       path={`${match.url}/booking`} component={ Booking } />
                <Route exact path={`${match.url}/lives`} component={ Lives } />
                <Route exact path={`${match.url}/streaming`} component={ Streaming } />
                <Route exact path={`${match.url}/account`} component={ Account } />
                <Redirect from={'*'} to={`${match.url}/planning`} />
              </Switch>
            </Layout>
          </LiveContext>
        </VideoContext>
        <HandleLocationChange />
      </ClubSelect>
    </React.Fragment>
  )
};

const HandleLocationChange = withRouter(
  ({children, location}) => {
    const {isDeviceMobile, setMenuExpanded} = useContextLayout();

    // ScrollToTop
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    // Hide Menu on Mobile device
    useEffect(() => {
      if(isDeviceMobile){
        setMenuExpanded(false)
      }
    }, [location]);

    return children || null;
  }
);

export default App;
