function request(path, method, _body, _headers) {
	const body = _body && JSON.stringify(_body);

	const headers = _headers || {};
	headers['content-type'] = 'application/json';
	headers['app-version'] = `${process.env.REACT_APP_VERSION}`;
	headers['with-cookie'] = 'true';
	headers['version-check-bypass'] = 'true';
	headers['platform'] = 'members';

	let origin = window.location.origin;
	if (!origin) {
		origin =
			window.location.protocol +
			'//' +
			window.location.hostname +
			(window.location.port ? ':' + window.location.port : '');
	}
	let fullPath = origin;
	if (path.startsWith('/api')) fullPath += path;
	else fullPath += '/api' + path;

	const controller = new AbortController();

	const query = fetch(fullPath, { body, headers, method, signal: controller.signal }).then((response) => {
		if (!response) Promise.reject(null);

		if (!response.ok) {
			return response.json().then((json) => {
				return Promise.reject(json);
			});
		}

		if (response.status === 204) return null;
		if (response.status === 201) return response;

		return response.json();
	});

	return [query, controller]
}

const Api = {
	DELETE: (path, body, headers) => request(path, 'DELETE', body, headers),
	GET: (path, body, headers) => request(path, 'GET', body, headers),
	PATCH: (path, body, headers) => request(path, 'PATCH', body, headers),
	POST: (path, body, headers) => request(path, 'POST', body, headers),
	PUT: (path, body, headers) => request(path, 'PUT', body, headers)
};

export default Api;
