//TODO make it works for more than deep = 2
export const toObj = value => Object.entries(value).reduce( (acc, [key,val]) => {
	if( key.indexOf('_') === -1 ) acc[key] = val;
	else {
		let [ parent, real ] = key.split('_');
		acc[parent] = acc[parent] || {};
		acc[parent][real] = val;
	}
	return acc;
}, {});

export const fromObj = () => {

};
