import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import {ToastRoot} from 'src/components/advanced/toastMessage/ToastManager';

ReactDOM.render(
  <React.StrictMode>
    <ToastRoot/>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
