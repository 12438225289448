import React, {useState, useImperativeHandle, useCallback, forwardRef} from 'react';

import Button from '../button/Button';
import {StyledForm} from './Form.style'
import PropTypes from "prop-types";

const Form = forwardRef(({ fields = [], onSubmit, children, disabled, patchMode = false, autoSubmitOnChange, complexChange, handleChange, ...rest }, ref) => {
	const [isLoading, setLoading] = useState(false);
		let _disabled = true;
		const getBaseState = useCallback(
			() =>
				fields.reduce((acc, f) => {
					acc[f.name] = f;
					return acc;
				}, {}),
			[fields]
		);
		const [state, change] = useState(getBaseState());

		const handleSubmit = (e, stateToUse) => {
			if (e) {
				e.preventDefault();
			}

			setLoading(true);

			const result = Object.values(stateToUse || state).reduce((acc, f) => {
				const field = fields.find((fi) => fi.name === f.name);
				if (!field) {
					return acc;
				}
				if (patchMode && field.value === f.value) {
					return acc;
				}
				acc[f.name] = f.value;
				return acc;
			}, {});

			const promiseAfterSubmit = onSubmit(result);
			if(typeof promiseAfterSubmit?.then === 'function'){
				promiseAfterSubmit.then(() => {
					setLoading(false);
				});
			} else {
				setLoading(false);
			}
		};

		const onChange = ({ name, value, valid, submit }) => {
			const changed = { ...state, [name]: { ...state[name], value, valid } };

			if( complexChange ) {
				change( complexChange({name, value, valid, newState:changed, oldState:state}) );
			} else if (handleChange) {
				change(changed);
				handleChange({ name, value, valid });
			} else {
				change(changed);
				if (autoSubmitOnChange || submit) {
					handleSubmit(null, changed);
				}
			}
		};

		useImperativeHandle(
			ref,
			() => ({
				reset: () => {
					const baseState = getBaseState();
					change(baseState);
				},
			}),
			[getBaseState]
		);

		const inputs = Object.entries(state).reduce((acc, [name, field]) => {
			acc[name] = field.inputType({ key: name, field, onChange });
			return acc;
		}, {});

		if (patchMode)
			_disabled = Object.values(state).some((v) => v.valid === false);
		else _disabled = Object.values(state).some((v) => !v.valid);

		inputs._submit = (
			<Button
				type="submit"
				title={onSubmit.label}
				imgIcon={onSubmit.imgIcon}
				iconBefore={onSubmit.iconBefore}
				theme={onSubmit.theme}
				stretch={onSubmit.stretch}
				margins={onSubmit.margins}
				onClick={handleSubmit}
				isLoading={isLoading}
				disabled={_disabled || disabled}
			/>
		);

		return (
			<StyledForm
				{...rest}
				onSubmit={handleSubmit}
				disabled={disabled}
				patchMode={patchMode}
			>
				{children(inputs)}
			</StyledForm>
		);
	}
);

Form.propTypes = {
	isManagingLoading: PropTypes.bool,
};

Form.defaultProps = {
	isManagingLoading: false,
};

export default Form;
