import React from "react";
import PropTypes from "prop-types";

import H1 from "../h1/H1";
import FlexWrapper from "../flexWrapper/FlexWrapper";
import { CardWrapper, CardHeader } from "./Card.style";

const Card = ({
  cardTitle,
  children,
  margins,
  onClick,
  className,
  topRight,
  style,
  textTransform,
  cardWith
}) => {
  return (
    <CardWrapper margins={margins} onClick={onClick} style={style} className={className} cardWith={cardWith}>
      {cardTitle && (
        <CardHeader>
          <H1 text={cardTitle} textTransform={textTransform} />
          {topRight}
        </CardHeader>
      )}
      <FlexWrapper paddings="1rem" flexDirections="column" margins="0">
        {children}
      </FlexWrapper>
    </CardWrapper>
  );
};

Card.propTypes = {
  cardTitle: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  margins: PropTypes.string,
};

export default Card;
