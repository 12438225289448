import styled from "styled-components";

import { ButtonStyles, SecondaryColors } from "../Theme";

export const StyledButton = styled.button`
  appearance: none;
  cursor: pointer;
  border-radius: ${({ theme }) => ButtonStyles[theme].borderRadius};
  font-weight: ${({ theme }) => ButtonStyles[theme].fontWeight};
  font-size: ${({ theme }) => {
    if (theme) return ButtonStyles[theme].fontSize;
  }};
  height: ${({ theme }) => ButtonStyles[theme].height};
  width: ${({ theme, stretch }) => {
    if (ButtonStyles[theme].width) return ButtonStyles[theme].width;

    if (stretch) return "100%";

    return "";
  }};
  color: ${({ theme }) => ButtonStyles[theme].color};
  background-color: ${({ theme }) => ButtonStyles[theme].backgroundColor};
  background: ${({ theme }) => ButtonStyles[theme].background};
  text-transform: ${({ theme }) => ButtonStyles[theme].textTransform};
  text-decoration: ${({ theme }) => ButtonStyles[theme].textDecoration};
  margin: ${({ margins }) => margins || ""};
  padding: ${({ theme, paddings }) =>
    ButtonStyles[theme].padding ? ButtonStyles[theme].padding : paddings || ""};
  display: ${({ theme }) => ButtonStyles[theme].display};
  align-items: ${({ theme }) => ButtonStyles[theme].alignItems};
  border: ${({ theme }) => ButtonStyles[theme].border || "none"};
  color: ${({ variantColor }) =>
    variantColor ? SecondaryColors.secondaryColorLightVariant : ""};
  box-shadow: ${({ theme }) => ButtonStyles[theme].boxShadow};
  letter-spacing: ${({ theme }) => ButtonStyles[theme].letterSpacing};

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => ButtonStyles[theme].disabledColor};
    background: ${({ theme }) => ButtonStyles[theme].disabledbackground};
    font-weight: ${({ theme }) => ButtonStyles[theme].fontWeightDisabled};
    width: ${({ stretch }) => (stretch ? "100%" : "auto")};

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: ${({ theme }) => ButtonStyles[theme].opacityHover};
  }
`;

export const SearchImg = styled.img`
  width: 1rem;
  max-width: 1rem;
`;

export const IconBeforeMargins = styled.img`
  max-width: 1rem;
  margin: ${({ iconBeforeMargins }) => iconBeforeMargins};
`;
