import styled from "styled-components";

import { PrimaryColors, primaryColorsAlpha } from "../Theme";
import { NavLink } from "react-router-dom";

export const MenuWrapper = styled.div`
  background-color: ${PrimaryColors.MainLightColor};
  padding: .5rem;
  display: flex;
  flex-direction: ${({ flexDirections }) => flexDirections};
  justify-content: ${({ justifyContent }) => justifyContent ? justifyContent : ""};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
  padding: ${({ paddings }) => (paddings ? paddings : "")};
  margin: ${({ margins }) => (margins ? margins : "")};
  width: ${({ wrapperWidth }) => (wrapperWidth ? wrapperWidth : "")};
  position: ${({ wrapperPosition }) =>
    wrapperPosition ? wrapperPosition : ""};
  border: ${({ borders }) => borders};

  > a {
    margin: 0 0.875rem;
    cursor: pointer;
    color: ${primaryColorsAlpha.MainDarkColor72};
    text-decoration: none;
    font-size: 0.875rem;

    &:first-child {
      margin-left: 0;
    }

    &.active {
      font-weight: bolder;
      border-bottom: 0.0625rem solid black;
      color: ${PrimaryColors.MainDarkColor};
    }
  }
`;

export const MenuLink = styled(NavLink)`
  //padding: 0 2rem;
`;
