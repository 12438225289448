import styled from "styled-components";
import { Link } from "react-router-dom";

import { PrimaryColors, primaryColorsAlpha } from "../Theme";

export const Root = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  height: 100%;
`;

export const CurrentPlace = styled.span`
  color: ${PrimaryColors.MainDarkColor};
  display: inline-block;
  font-weight: bolder;
  overflow: hidden;
  padding-left: 1rem;

  &:focus {
    text-decoration: underline;
  }
`;

export const OpenButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
  outline: none;
  padding: 0;
  user-select: none;
`;

export const ArrowImg = styled.img`
  display: inline-block;
  margin-left: 0.5rem;
  transform: ${({ isOpened }) => `rotate(${isOpened ? "0" : "-90deg"})`};
`;

export const Dropdown = styled.div`
  background-color: white;
  box-shadow: 0.25rem 0.25rem 0.25rem 0.25rem
    ${primaryColorsAlpha.MainDarkColor08};
  max-height: 14rem;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 24rem;
  z-index: 2000;
`;

export const PlacesList = styled.ul`
  list-style-type: none;
`;

export const Place = styled.li`
  border-top: 0.0625rem solid ${primaryColorsAlpha.MainDarkColor08};
  cursor: pointer;
  font-weight: bolder;
  list-style: none;
  padding: 1rem 0;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: ${PrimaryColors.MainLightColor};
  }

  a {
    color: ${PrimaryColors.MainDarkColor};
    text-decoration: none;
  }
`;

export const PlaceLink = styled(Link)`
  display: block;
  padding: 0 1rem;
`;
