import styled from 'styled-components';

import {PrimaryColors, primaryColorsAlpha} from '../Theme';

export const StyledLabel = styled.label`
    width: ${({stretch}) => stretch ? '100%' : '34.375rem'};
    margin: ${({margins}) => margins || ''};
    display: block;
    font-weight: bolder;
`

export const StyledInput = styled.input`
    border: .0625rem solid ${primaryColorsAlpha.MainDarkColor08};
    background-color: ${PrimaryColors.WhiteColor};
    color: ${primaryColorsAlpha.MainDarkColor56};
    padding: .5rem 1rem;
    width: ${({stretch}) => stretch ? '100%' : '34.375rem'};
    border-radius: .25rem;
    font-weight: normal;

    &:focus {
        outline: none;
    }
`
