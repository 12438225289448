import styled from 'styled-components';

export const OverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ isOpen }) => (isOpen ? 11 : '')};
  width: 100%;
  background-color: ${({ alphaBackground }) =>
    alphaBackground ? 'transparent' : 'rgba(0,0,0,.8)'};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  height: ${({ isOpen }) => (isOpen ? '100vh' : 0)};
  padding: 0;
  margin: 0;
  backdrop-filter: ${({ alphaBackground }) =>
    alphaBackground ? null : 'saturate(80%) blur(15px)'};
`;
