import styled from "styled-components";

import { PrimaryColors, primaryColorsAlpha } from "../Theme";

export const CardWrapper = styled.div`
  background-color: ${PrimaryColors.WhiteColor};
  border: 0.0625rem solid ${primaryColorsAlpha.MainDarkColor08};
  border-radius: 0.25rem;
  margin: ${({ margins }) => margins};
  width: ${({cardWith}) => cardWith ? cardWith : ''};
`;

export const CardHeader = styled.header`
  padding: 1rem;
  border-bottom: 0.0625rem solid ${primaryColorsAlpha.MainDarkColor08};
  display: flex;
  align-items: center;
  justify-content: space-between;

  > h1 {
    text-transform: ${({textTransform}) => textTransform ? 'uppercase' : ''};
    font-weight: bolder;
  }
`;
