import styled from 'styled-components';

import {primaryColorsAlpha} from '../Theme';

export const StyledH6 = styled.h6`
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: initial;
    color: ${primaryColorsAlpha.MainDarkColor56};
    margin: 0;
    line-height: 2;
`
