import styled from "styled-components";
import { Link } from "react-router-dom";

import { PrimaryColors, primaryColorsAlpha } from "../commons/Theme";

export const AppHeader = styled.header`
  box-shadow: 0 0.125rem 0.25rem 0 ${primaryColorsAlpha.MainDarkColor08};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  height: 4rem;
  background-color: ${PrimaryColors.WhiteColor};
  z-index: 3;
  width: 100%;
  padding: 0 1rem;
`;

export const AppBrandWrapper = styled(Link)`
  text-decoration: none;
  color: ${PrimaryColors.MainDarkColor};
  display: flex;
  align-items: center;
  height: 100%;
  border-right: 1px solid ${primaryColorsAlpha.MainDarkColor16};
  padding-right: 1rem;
`;

export const AppBrand = styled.span`
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 0.06rem;

  span {
    font-size: 0.75rem;
    font-weight: initial;
    font-style: italic;
  }
`;

export const CcLogo = styled.img`
  margin-right: 0.5rem;
  height: 1.25rem;
`;
