import React from 'react'
import PropTypes from 'prop-types'

import {StyledH6} from './H6.style'

const H6 = ({text}) => {
    return (
        <StyledH6>
            {text}
        </StyledH6>
    )
}

H6.propTypes = {
    text: PropTypes.string.isRequired,
}

export default H6
