import React from "react";
import PropTypes from "prop-types";
import IconAutorenew from "@material-ui/icons/Autorenew";

import "./RealoadIcon.css";

const ReloadIcon = ({active}) => {
  return (
    <div className="AnimationTurn">
      <IconAutorenew/>
    </div>
  );
};

ReloadIcon.propTypes = {
  active: PropTypes.bool,
};

ReloadIcon.defaultProps = {
  active: true,
};

export default ReloadIcon;
