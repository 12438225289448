import React, { useMemo, useState } from "react";

import {
  ArrowImg,
  CurrentPlace,
  Dropdown,
  OpenButton,
  PlacesList,
  Place,
  PlaceLink,
  Root,
} from "./ClubsChooser.style";

import { useSelectedClub } from "../../../ClubSelect";

import Overlay from "../overlay/Overlay";

import ArrowDown from "../../../assets/icons/ic_arrowDown.svg";

export default function ClubsChooser() {
  const [selectedClub, , clubList] = useSelectedClub();
  const [isOpened, setIsOpened] = useState(false);
  const hasManyClubs = useMemo(() => {
    return clubList.length > 1;
  }, [clubList]);

  const handleToggleList = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Root>
      <>
        {hasManyClubs ? (
          <OpenButton type="button" onClick={handleToggleList}>
            <CurrentPlace>{selectedClub.name}</CurrentPlace>
            <ArrowImg src={ArrowDown} alt="" isOpened={isOpened} />
          </OpenButton>
        ) : (
          <CurrentPlace>{selectedClub.name}</CurrentPlace>
        )}
      </>
      {isOpened && (
        <>
          <Overlay alphaBackground isOpen onClick={handleToggleList} />
          <Dropdown>
            <PlacesList>
              {clubList
                .filter((p) => p.id !== selectedClub.id)
                .map((p) => (
                  <Place key={p.id}>
                    <PlaceLink to={`/club/${p.id}`} onClick={handleToggleList}>
                      {p.name}
                    </PlaceLink>
                  </Place>
                ))}
            </PlacesList>
          </Dropdown>
        </>
      )}
    </Root>
  );
}
