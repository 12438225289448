import {Grid} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import apiMobile from 'src/apiMobile';
import Button from 'src/components/commons/button/Button';
import Card from 'src/components/commons/card/Card';
import FlexWrapper from 'src/components/commons/flexWrapper/FlexWrapper';
import Input from 'src/components/commons/input/Input';
import Loader from 'src/Loader';
import ModalAccountDelete from 'src/modules/Account/ModalAccountDelete';
import {genderToLabel} from 'src/utils/genderLegacy';

const Account = ({match}) => {
  const [accountData, setAccountData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const [query, controller] = apiMobile.athlete.cGet('me');
    query.then(accountSupplied => {
      setAccountData(accountSupplied);
    }).catch(() => {});
    return () => controller.abort();
  }, []);

  if (accountData === null) {
    return <Loader/>
  }

  const modalToggle = () => {
    setModalVisible((v) => !v);
  }

  const birthdateLabel = accountData.birthdate ? moment(accountData.birthdate).format('LL') : 'Inconnu';
  const genderLabel = genderToLabel(accountData.gender);
  return (
    <div>
      <Card cardTitle="Mes informations">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input field={{stretch: true, label: 'Nom', disabled: true, value: accountData.lastname}}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input field={{stretch: true, label: 'Prénom', disabled: true, value: accountData.firstname}}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input field={{stretch: true, label: 'E-mail', disabled: true, value: accountData.email}}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input field={{stretch: true, label: 'Date de naissance', disabled: true, value: birthdateLabel}}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input field={{stretch: true, label: 'Numéro de téléphone', disabled: true, value: accountData.phone}}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <Input field={{stretch: true, label: 'Sexe', disabled: true, value: genderLabel}}/>
          </Grid>
        </Grid>
      </Card>
      <FlexWrapper justifyContent="center" margins="2rem">
        <Button theme="alert" title="Supprimer définitivement mon compte et toutes mes données associées" onClick={modalToggle}/>
      </FlexWrapper>
      {!modalVisible ? null : <ModalAccountDelete onClose={modalToggle}/>}
    </div>
  );
};

export default Account;
