import React, { useState, useEffect } from 'react';
import moment from 'moment';

import FlexWrapper from "../../components/commons/flexWrapper/FlexWrapper";
import apiMobile from "../../apiMobile";
import { createNotification } from "../../components/advanced/toastMessage/ToastManager";
import { useSelectedClub } from "../../ClubSelect";
import Text from "../../components/commons/text/Text";
import Loader from "../../Loader";
import ViewCard from "../../components/viewCard/ViewCard";

const Subscription = () => {
  const now = moment();
  const [ selectedClub ] = useSelectedClub();
  const [ state, setState ] = useState({ step: "INIT", current: [], old: [] });

  const filterByClub = (product) => product.club.id === selectedClub.id;
  const filterByActive = (product) => moment(product.expireAt).valueOf() >= now.valueOf();
  const filterByExpired = (product) => moment(product.expireAt).valueOf() < now.valueOf();

  const load = () => {
    apiMobile.product.search({})
    .then(product => {
      setState({
        step: "OK",
        current: product.filter(filterByClub).filter(filterByActive),
        old: product.filter(filterByClub).filter(filterByExpired)
      });
    })
    .catch(err => {
      console.error(err);
      createNotification("Erreur", { type: 'danger', message: err.userMsg || "Une erreur est survenue"});
    });
  };

  useEffect(() => {
    if (["ERROR", "OK", "LOADING"].indexOf(state.step) >= 0) return ;
    setState({ step: "LOADING", current: [], old: [] });
    load();
  }, [ state.step ]);

  if (state.step === "LOADING") return <FlexWrapper><Loader/></FlexWrapper>;

  return (
    <FlexWrapper margins={'1rem 0'} flexDirections={'column'} alignItems={'center'} justifyContent={'space-between'}>
      <FlexWrapper flexDirections={'column'} wrapperWidth={'100%'}>
        <Text text={"Actifs"} />
        { state.current.map(p => <ViewCard key={p.id} data={p} withOutPicture />)}
        { !state.current.length && <ViewCard data={{ name: "Aucun abonnement actifs" }} withOutPicture />}
      </FlexWrapper>

      <FlexWrapper flexDirections={'column'} wrapperWidth={'100%'}>
        <Text text={"Expirés/finis"} />
        { state.old.map(p => <ViewCard key={p.id} data={p} withOutPicture />)}
        { !state.old.length && <ViewCard data={{  name: "Aucun abonnement passé" }} withOutPicture />}
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default Subscription;
