import React, { useState } from 'react';

import { toObj } from "./app/helpers/fields";
import Form from "./components/commons/form/Form";
import Input from "./components/commons/input/Input";
import FlexWrapper from "./components/commons/flexWrapper/FlexWrapper";
import Button from "./components/commons/button/Button";
import apiMobile from "./apiMobile";
import { createNotification } from "./components/advanced/toastMessage/ToastManager";

const Login = ({ signIn }) => {
	const [ isForgot, setForgot ] = useState(false);
	const showForgotPassword = () => setForgot(!isForgot);

	const _signIn = (value) => {
		const body = toObj(value);
		signIn(body);
	};
	_signIn.label = 'Connexion';
	_signIn.margins = '1rem 0';

	const disabled = false;

	const fields = [
		{ name: 'email', inputType: Input, type: 'text', label: "E-mail", stretch: false },
		{ name: 'password', inputType: Input, type: 'password', label: "Mot de passe", stretch: false },
	];

	const display = ({_submit, ...rest}) => (
		<FlexWrapper flexDirections={'column'} justifyContent={'space-between'} alignItems={'center'} margins={'1rem 1rem'}>
			{rest.email}
			{rest.password}
			{_submit}
		</FlexWrapper>
	);

	if (!isForgot)
		return (
			<FlexWrapper className='Login' flexDirections={'column'} wrapperWidth={'100%'} alignItems={'center'} justifyContent={'space-between'} margins={'1rem'}>
				<h1>Connexion</h1>
				<Form onSubmit={_signIn} fields={fields} disabled={disabled}>
					{display}
				</Form>
				<FlexWrapper justifyContent={'center'} flexDirections={'column'} alignItems={'center'}>
					<p>
						<small style={{fontSize:"0.8rem"}}>
							Si vous n&apos;avez jamais défini de mot de passe, utilisez la fonctionnalité &quot;Mot de passe oublié&quot; pour en créer un.
						</small>
					</p>
					<p>
						<small style={{fontSize:"0.8rem"}}>Si vous n&apos;avez pas de compte, contactez votre club pour qu&apos;il vous en crée un.</small>
					</p>
					<Button
						margins={'1rem'}
						title={"Mot de passe oublié"}
						onClick={showForgotPassword}
						theme={'secondary'}
					/>
				</FlexWrapper>
			</FlexWrapper>
		);
	else return <ForgotPassword showForgotPassword={showForgotPassword} signIn={_signIn}/>;
};

const ForgotPassword = ({ showForgotPassword, signIn }) => {
	const [ codeSend, setCodeSend ] = useState(false);

	const _forgotPassword = (value) => {
		const body = toObj(value);

		apiMobile.passwordRecovery.create({ athlete: body })
		.then(() => {
			createNotification("Code envoyé", { message: "Un code vous a été envoyé par e-mail"});
			setCodeSend(value.email);
		})
		.catch(err => {
			if (err.errors['client.email'] === "mustExists")
				createNotification("Erreur", { type: 'danger', message: "Aucun utilisateur avec cet e-mail" });
			else {
				createNotification("Erreur", { type: 'danger', message: "Une erreur s'est produite" });
				console.error(err);
			}
		});
	};
	_forgotPassword.label = 'Réinitialiser le mot de passe';
	_forgotPassword.margins = '1rem 0';
	const disabled = false;

	const forgotFields = [
		{ name: 'email', inputType: Input, type: 'text', label: "Email", stretch: false },
	];

	const displayForgot = ({_submit, ...rest}) => (
		<FlexWrapper flexDirections={'column'} justifyContent={'space-between'} alignItems={'center'} margins={'1rem 1rem'}>
			{rest.email}
			{_submit}
		</FlexWrapper>
	);
	if (codeSend) return <ResetPassword signIn={signIn} email={codeSend} />;

	return (
		<FlexWrapper className='Login' flexDirections={'column'} wrapperWidth={'100%'} alignItems={'center'} justifyContent={'space-between'} margins={'1rem'}>
			<h1>Mot de passe oublié</h1>
			<Form onSubmit={_forgotPassword} fields={forgotFields} disabled={disabled}>
				{displayForgot}
			</Form>
			<FlexWrapper justifyContent={'center'} flexDirections={'column'} alignItems={'center'}>
				<p>
					<small style={{fontSize:"0.8rem"}}>
						Si vous n&apos;avez pas de compte, contactez votre club pour qu&apos;il vous en crée un.
					</small>
				</p>
				<Button
					margins={'1rem'}
					title={"Connexion"}
					onClick={showForgotPassword}
					theme={'secondary'}
				/>
			</FlexWrapper>
		</FlexWrapper>
	);
};

const ResetPassword = ({ signIn, email }) => {
	const _resetPassword = (value) => {
		const body = toObj(value);
		body.email = email;
		apiMobile.passwordRecovery.edit(body.code, { athlete: body })
		.then(() => {
			createNotification("Mot de passe changé", { message: 'Connexion en cours' });
			signIn({ email: email, password: body.password });
		})
		.catch(err => {
			if (err.restCode === "resourceNotFound")
				createNotification("Erreur", { type: 'danger', message: 'Le code est incorrect'});
			else if (err.restCode === "resourceGone")
				createNotification("Erreur", { type: 'danger', message: 'Le code a déjà été utilisé, ou le délai est dépassé'});
			else {
				createNotification("Erreur", { type: 'danger', message: "Une erreur c'est produite" });
				console.error(err);
			}
		});
	};
	_resetPassword.label = 'Enregistrer le mot de passe';
	_resetPassword.margins = '1rem 0';
	const disabled = false;

	const fields = [
		{ name: 'code', inputType: Input, type: 'text', label: "Code", stretch: false },
		{ name: 'password', inputType: Input, type: 'password', label: "Nouveau mot de passe", stretch: false },
	];

	const display = ({_submit, ...rest}) => (
		<FlexWrapper flexDirections={'column'} justifyContent={'space-between'} alignItems={'center'} margins={'1rem 1rem'}>
			{rest.code}
			{rest.password}
			{_submit}
		</FlexWrapper>
	);

	return (
		<FlexWrapper className='Login' flexDirections={'column'} wrapperWidth={'100%'} alignItems={'center'} justifyContent={'space-between'} margins={'1rem'}>
			<h1>Mot de passe oublié</h1>
			<Form onSubmit={_resetPassword} fields={fields} disabled={disabled}>
				{display}
			</Form>
			<FlexWrapper justifyContent={'center'} flexDirections={'column'} alignItems={'center'}>
				<p>
					<small style={{fontSize:"0.8rem"}}>
						Utilisez le code reçu sur l'adresse e-mail précédente
					</small>
				</p>
			</FlexWrapper>
		</FlexWrapper>
	);
};

export default Login;
