import React from "react";
import PropTypes from "prop-types";

import { Wrapper } from "./FlexWrapper.style";

const FlexWrapper = ({
  flex,
  children,
  flexDirections,
  justifyContent,
  alignItems,
  paddings,
  margins,
  wrapperWidth,
  wrapperHeight,
  wrapperPosition,
  borders,
  wrapperTop,
  onClick,
  withBackground,
  gap,
  style,
}) => {
  return (
    <Wrapper
      flexDirections={flexDirections}
      justifyContent={justifyContent}
      alignItems={alignItems}
      paddings={paddings}
      margins={margins}
      wrapperWidth={wrapperWidth}
      wrapperHeight={wrapperHeight}
      wrapperPosition={wrapperPosition}
      borders={borders}
      flex={flex}
      wrapperTop={wrapperTop}
      onClick={onClick}
      withBackground={withBackground}
      gap={gap}
      style={style}
    >
      {children}
    </Wrapper>
  );
};

FlexWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  flexDirections: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  paddings: PropTypes.string,
  margins: PropTypes.string,
  wrapperWidth: PropTypes.string,
  wrapperPosition: PropTypes.string,
  borders: PropTypes.string,
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FlexWrapper.defaultProps = {
  flexDirections: "row",
};

export default FlexWrapper;
