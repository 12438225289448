import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useSelectedClub } from "../../ClubSelect";
import apiMobile from "../../apiMobile";
import moment from "moment";

export const Context = createContext();
export const useContextLive = () => useContext(Context);

const timeout = 60000;

const LiveContext = ({ children }) => {
  const [ selectedClub ] = useSelectedClub();
  const [ liveOnGoing, setLiveOngoing] = useState();
  const [ liveSeance, setLiveSeance] = useState();
  const [ liveServerPlaylistUrl, setLiveServerPlaylistUrl ] = useState();
  const timerToClearSomewhere = useRef(null);

  const checkLiveSeance = () => {
    const now = moment();

    apiMobile.live.search({
      clubs: selectedClub.id,
      date_min: now.format('YYYY-MM-DDTHH:mm:ss'),
      date_max: now.add(7, 'day').format('YYYY-MM-DDTHH:mm:ss'),
      isLive: true
    })
    .then( seances => {
      const nowSeance = moment();
      const onGoingLiveSeance = seances.find((seance) => {
        const start = moment(seance.dates.starts);
        const end = moment(seance.dates.ends);
        return nowSeance.isAfter(start) && nowSeance.isBefore(end);
      });
      if (onGoingLiveSeance !== undefined) {
        setLiveOngoing(true);
        setLiveSeance(onGoingLiveSeance);
      } else {
        setLiveOngoing(false);
      }
    })
    .catch( () => {} )
    .finally(() => {
      timerToClearSomewhere.current = setTimeout(() => {
        checkLiveSeance();
      }, timeout);
    });
  };

  useEffect(() => {
    checkLiveSeance();
    return () => {
      clearInterval(timerToClearSomewhere.current);
    };
  }, [ selectedClub.id ]);

  useEffect(() => {
    apiMobile.liveLink.get(selectedClub.id)
    .then((supplyData) => {
      if (supplyData.liveServerSecureLink !== null) {
        setLiveServerPlaylistUrl(supplyData.liveServerSecureLink);
      }
    })
    .catch(() => {});
  }, [ selectedClub.id ]);

  return (
    <Context.Provider value={[ liveOnGoing, liveSeance, liveServerPlaylistUrl ]}>
      {children}
    </Context.Provider>
  );
};

export default LiveContext;
