import styled from "styled-components";

export const Div = styled.div`
	display: flex;
    flex-direction: row;
    min-height: 100vh;
`

export const LayoutWrapper = styled.main`
  margin-top: 4rem;
  margin-left: ${props => props.hasMarginLeft ? '15rem' : '0'};
  flex-grow: 1;
  transition: margin-left 0.3s ease-out;
`;
