import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelectedClub } from "../../ClubSelect";
import apiMobile from "../../apiMobile";

export const Context = createContext();
export const useContextVideo = () => useContext(Context);

const libraries = [
  {
    name: 'vimeo',
    title: 'Vidéos de votre club',
    coverPicture: "defaultCover"
  },
  {
    name: 'lesMills',
    title: 'Les Mills',
    coverPicture: "lesMills"
  },
  {
    name: 'clubVOD',
    title: 'Vidéos de votre club',
    coverPicture: "defaultCover"
  },
  {
    name: 'live',
    title: 'Planning des lives',
    coverPicture: "liveCover"
  },
];

const VideoContext = ({ children }) => {
  const [ selectedClub ] = useSelectedClub();
  const [ videos, setVideos ] = useState(false);
  const [ library, setLibrary ] = useState(false);

  const reload = () => {
    const videoLibrariesNameCheck = libraries.map((videoLibrary) => {
      const query = apiMobile['videoLibrary/checkAccess'].search({
        clubId: selectedClub.id,
        videoLibrary: videoLibrary.name
      });
      return query.then(() => videoLibrary).catch(() => null);
    });

    Promise.all(videoLibrariesNameCheck)
    .then(videoLibrary => {
      const availableLibraries = videoLibrary.filter((x) => x !== null);
      setLibrary(availableLibraries);
      return Promise.all(
        availableLibraries
        .filter(f => f.name !== 'live')
        .map(library => apiMobile['videoLibrary/search'].search({
            clubId: selectedClub.id,
            videoLibrary: library.name
          })
        )
      );
    })
    .then(videos => {
      setVideos(videos);
    })
    .catch(err => {
      console.log(err, 'error');
    });
  };

  useEffect(reload, [ selectedClub.id ]);

  return (
    <Context.Provider value={[ videos, library, reload ]}>
      {children}
    </Context.Provider>
  );
};

export default VideoContext;
