import React, { useEffect } from "react";
import PropTypes from "prop-types";

import H1 from "../h1/H1";
import FlexWrapper from "../flexWrapper/FlexWrapper";
import CloseIcon from "../../../assets/icons/ic_close.svg";
import { ModalWrapper, CloseWrapper } from "./Modal.style";
import { primaryColorsAlpha } from "../Theme";

const Modal = ({ children, onClick, modalTitle }) => {

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden");

    const close = (e) => {
      if (e.keyCode !== 27 /* ESC */) return;
      document.body.classList.remove("overflow-hidden");
      onClick();
    };

    window.addEventListener("keydown", close);
    return () => {
      document.body.classList.remove("overflow-hidden");
      window.removeEventListener("keydown", close);
    };
  }, [onClick]);

  return (
    <ModalWrapper>
      <FlexWrapper
        justifyContent="space-between"
        paddings="1rem"
        borders={`.0625rem solid ${primaryColorsAlpha.MainDarkColor08}`}
      >
        <H1 text={modalTitle} textTransform />
        <CloseWrapper onClick={onClick}>
          <img src={CloseIcon} alt="" />
        </CloseWrapper>
      </FlexWrapper>
      {children}
    </ModalWrapper>
  );
};

Modal.propTypes = {
  children: PropTypes.objectOf(Object).isRequired,
  onClick: PropTypes.func,
  modalTitle: PropTypes.string.isRequired,
};

export default Modal;
