/**
 * Colors
 */
export const PrimaryColors = {
  MainDarkColor: "rgb(9, 25, 21)", // Dark grey
  MainLightColor: "rgb(249, 249, 249)", // Light grey, body background
  WhiteColor: "rgb(255, 255, 255)", // White
  primaryColor: "rgb(238, 110, 115)", // Orange
};

export const SecondaryColors = {
  secondaryColor: "rgb(0, 51, 71)", // Dark blue
  secondaryColorLightVariant: "rgb(4, 69, 95)",
  secondaryColorLighterVariant: "rgb(6, 86, 119)",
  alertColor: "rgb(190, 5, 18)", // Red
  dangerColor: "rgb(250, 192, 35)", // Orange
  successColor: "rgb(0, 168, 150)", // Green
};

export const primaryColorsAlpha = {
  MainDarkColor08: "rgba(9, 25, 21, .08)",
  MainDarkColor16: "rgba(9, 25, 21, .16)",
  MainDarkColor32: "rgba(9, 25, 21, .32)",
  MainDarkColor56: "rgba(9, 25, 21, .56)",
  MainDarkColor72: "rgba(9, 25, 21, .72)",

  MainLightColor90: "rgba(249, 249, 249, .9)", // Overlay background

  WhiteColor72: "rgba(255, 255, 255, .72)",
};

/**
 * Buttons
 */
const basicButton = {
  borderRadius: ".25rem",
  color: PrimaryColors.WhiteColor,
  border: `none`,
  backgroundColor: PrimaryColors.primaryColor,
  fontWeight: "bolder",
  padding: ".5rem 1.5rem",
  boxShadow: `.15rem .15rem .25rem ${primaryColorsAlpha.MainDarkColor16}`,
  disabledColor: primaryColorsAlpha.WhiteColor,
  disabledbackground: primaryColorsAlpha.MainDarkColor16,
  opacityHover: 0.8,
  letterSpacing: "0.06rem",
  fontSize: ".875rem",
};

export const ButtonStyles = {
  primary: {
    ...basicButton,
  },
  secondary: {
    ...basicButton,
    backgroundColor: SecondaryColors.secondaryColor,
  },
  filled: {
    ...basicButton,
    backgroundColor: 'transparent',
    border: `.0625rem solid ${PrimaryColors.MainDarkColor}`,
    color: PrimaryColors.MainDarkColor,
    boxShadow: 'inherit'
  },
  filledDanger: {
    ...basicButton,
    backgroundColor: 'transparent',
    border: `.0625rem solid ${SecondaryColors.alertColor}`,
    color: SecondaryColors.alertColor,
    boxShadow: 'inherit'
  },
  alert: {
    ...basicButton,
    backgroundColor: SecondaryColors.alertColor,
  },
  danger: {
    ...basicButton,
    backgroundColor: SecondaryColors.dangerColor,
  },
  success: {
    ...basicButton,
    backgroundColor: SecondaryColors.successColor,
  },
  search: {
    borderRadius: ".25rem",
    border: `.0625rem solid ${primaryColorsAlpha.MainDarkColor16}`,
    backgroundColor: "transparent",
    padding: ".5rem 1rem",
  },
  rounded: {
    borderRadius: "50%",
    fontWeight: "bolder",
    fontSize: "1.25rem",
    height: "3rem",
    width: "3rem",
    color: PrimaryColors.WhiteColor,
    backgroundColor: SecondaryColors.secondaryColor,
    textTransform: "uppercase",
    padding: 0,
  },
  textOnly: {
    fontWeight: "bolder",
    fontWeightDisabled: "inherit",
    backgroundColor: "transparent",
    padding: 0,
    display: "flex",
    alignItems: "center",
    disabledColor: primaryColorsAlpha.MainDarkColor56,
  },
};
