import React from 'react'
import PropTypes from 'prop-types'

import {Title} from './H1.style'

const H1 = ({text, textSize, textTransform}) => {
    return (
        <Title textSize={textSize} textTransform={textTransform}>{text}</Title>
    )
}

H1.propTypes = {
    text: PropTypes.string.isRequired,
    textSize: PropTypes.string,
}

H1.defaultProps = {
    textSize: '16px'
}

export default H1
