import React from 'react';

import Loader from "./Loader";
import Image from "./Image";

import './MsgPage.scss';

import errorImage from './icons/error.svg';
import warningImage from './icons/warning.svg';
import successImage from './icons/success.svg';
import emptyImage from './icons/empty.svg';

const MsgPage = ({type, title, children}) => {
	let image, _title = title;

	switch (type) {
		case 'ERROR' :
			image = errorImage;
			_title = _title || "Erreur ! :(";
			break;
		case 'WARNING' :
			image = warningImage;
			_title = _title ||  "Attention ! :/";
			break;
		case 'LOADING' :
			_title = _title ||  "Chargement...";
			break;
		case 'SUCCESS' :
			image = successImage;
			_title = _title ||  "Terminé :)";
			break;
		case 'EMPTY' :
			image = emptyImage;
			_title = _title || "Aucun élément";
			break;

		default :
			image = warningImage;
			_title = "Unhandled warning :S";
			break;
	}

	return (
		<div className="MsgPage">
			{
				type === 'LOADING'
				? <Loader/>
				: <Image src={image} alt={type}/>
			}
			<strong>{_title}</strong>
			{ !!children && <p>{children}</p> }
		</div>
	);
};

export default MsgPage;
