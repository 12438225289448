import React from "react";
import PropTypes from "prop-types";

import { OverlayWrapper } from "./Overlay.style";

const Overlay = ({ isOpen, onClick, alphaBackground }) => {
  return (
    <OverlayWrapper
      isOpen={isOpen}
      onClick={onClick}
      alphaBackground={alphaBackground}
    />
  );
};

Overlay.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Overlay;
