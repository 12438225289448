import React, { useState, createContext, useContext, useEffect } from 'react';

import apiMobile from './apiMobile';
import { useSession } from "./Session";

export const Context = createContext();
export const useUserClubs = () => useContext(Context);

const UserClubs = ({ children }) => {
	const [{ user }] = useSession();
	const [ clubList, clubListSet ] = useState(false);

	const loadClubs = () => {
		clubListSet(false);
		apiMobile.club.search({})
		.then( clubs => {
			clubListSet(
				clubs.filter(c => user.clubs.find(uc => uc.id === c.id))
			);
		})
	};

	useEffect(loadClubs, []);

	if ( clubList === false )
		return null;

	return (
		<Context.Provider value={clubList}>
			{children}
		</Context.Provider>
	);
};

export default UserClubs;
