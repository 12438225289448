import React from "react";

export default class Img extends React.Component{

	constructor(props){
		super(props);
		
		let isLazy = false;
		if (typeof(this.props.lazySrc) !== 'undefined' && !!window.IntersectionObserver) {
			isLazy = true;
		}

		this.state = {isLazy};
		this.img = React.createRef();
	}

	componentDidMount(){
		if (!this.state.isLazy) {
			return;
		}

		this.imageObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					this.imageObserver.unobserve(entry.target);
					console.debug('[LazyImg]', 'unlazy', this.props.src);
					this.setState({isLazy: false});
				}
			});
		});
		this.imageObserver.observe(this.img.current);
	}

	componentWillUnmount() {
		this.imageObserver && this.imageObserver.unobserve(this.img.current);
	}

	render() {
		let {src, alt} = this.props;
		
		if (this.state.isLazy) src = this.props.lazySrc;

		if (!alt)  console.warn('[Img]', 'No alt property for src=', src);

		return <img src={src} alt={alt} ref={this.img} className={this.props.className || ''}/>;
	}
}
