import React, { useEffect, useState } from 'react';
import moment from "moment";

import apiMobile from "../../apiMobile";
import { createNotification } from "../../components/advanced/toastMessage/ToastManager";
import { useSelectedClub } from "../../ClubSelect";
import ViewCard from "../../components/viewCard/ViewCard";
import FlexWrapper from "../../components/commons/flexWrapper/FlexWrapper";
import Button from "../../components/commons/button/Button";
import Text from "../../components/commons/text/Text";
import VideoEmbedPlayer from "../../components/advanced/embdedVideo/VideoEmbedPlayer";
import { PrimaryColors } from "../../components/commons/Theme";
import { useContextLive } from "../../components/context/LiveContext";

const Lives = () => {
  const [ selectedClub ] = useSelectedClub();
  const [ liveOnGoing, liveSeance, liveServerPlaylistUrl ] = useContextLive();
  const [ selectedSeance, setSelectedSeance ] = useState(false);
  const [ state, setState ] = useState({
    step: 'INIT',
    seances: [],
    query: {
      date_min: moment().toDate(),
      date_max: moment().add(7, 'day').toDate(),
      isLive: true,
      broadcastClubs: true,
    }
  });

  const loadLiveSeance = () => {
    if (['LOADING', 'OK', 'ERROR'].indexOf(state.step) >= 0) return ;
    setState({ step: 'LOADING', seances: [], query: state.query });
    apiMobile.live.search({
      clubs: selectedClub.id,
      ...state.query
    })
    .then(seances => {
      const byDay = [];
      seances.forEach(s => {
        const section = moment(s.starts).format('YYYY-MM-DD');
        const foundSection = byDay.findIndex(bd => bd.title === section);
        if (foundSection < 0) {
          byDay.push({title: section, data: [s]});
        } else {
          byDay[foundSection].data.push(s);
        }
      });
      setState({ step: 'OK', seances: byDay, query: state.query });
    })
    .catch(err => {
      console.error(err);
      setState({ step: 'ERROR', seances: [], query: state.query });
      createNotification('Erreur', { type: 'danger', message: err.userMsg || "Une erreur est survenue" });
    });
  };

  useEffect(loadLiveSeance, [ state.step ]);

  const reload = () => setState({ ...state, step: 'RELOAD' });
  const bookingManager = (seance) => {
    const p = [];
    if (!seance.booking) {
      p.push(
        apiMobile.booking.create({
          seance: { id: seance.id },
          waiting: false
        })
      );
    } else {
      p.push(
        apiMobile.booking.delete(seance.booking.id)
      );
    }

    Promise.all(p)
    .then(() => {
      createNotification('', {
        message: `Votre ${!seance.booking ? "réservation" : "annulation"} a bien été acceptée`
      });
      reload();
    })
    .catch(err => {
      createNotification('Erreur', {
        type: 'danger',
        message: err.userMsg || "Une erreur est survenue"
      });
    });
  };

  const openDetail = (value) => setSelectedSeance(value);

  if (!!selectedSeance) return (<ViewLiveDetail
    liveData={[ liveOnGoing, liveSeance, liveServerPlaylistUrl ]}
    seance={selectedSeance}
    back={ () => setSelectedSeance(false) }
  />);

  return (
    <>
      { !!liveSeance && !!liveOnGoing &&
        <Button
          onClick={openDetail.bind(this, liveSeance)}
          title={`Voir le live : ${liveSeance.name}`}
          margins={'.5rem 0'}
        />
      }
      { !!state.seances.length && state.seances.map((s,i) => (
        <ByDayView key={i} seances={s} openDetail={openDetail} bookingManager={bookingManager} />
      ))}
      { state.seances.length <= 0 && <ViewCard
        data={{ name: "Il n'y a aucun live à venir !" }}
        withOutPicture
      /> }
    </>
  );
};

const ByDayView = ({ seances, openDetail, bookingManager }) => {
  return (
    <FlexWrapper flexDirections={'column'}>
      <Text
        text={
          moment(seances.title).format('ll')
        }
        style={{
          borderRadius: '.25rem',
          padding: '.5rem 0 .5rem 5rem',
          backgroundColor: PrimaryColors.MainLightColor
        }}
      />
      { !!seances.data.length && seances.data.map(s => (
        <ViewCard
          key={s.id}
          data={s}
          onDetail={ openDetail.bind(this, s) }
          onClick={ !!s.bookable.enabled ? bookingManager.bind(this, s) : null}
          clickText={!s.booking ? "Réserver" : "Annuler"}
        />
      ))}
    </FlexWrapper>
  );
};

const ViewLiveDetail = ({ liveData, seance, back }) => {
  const [ onGoing, liveSeance, liveUrl ] = liveData;
  return (
    <FlexWrapper flexDirections={'column'}>
      <FlexWrapper flexDirections={'row'} margins={'0 0 1rem 0'}>
        <Button onClick={back} title={'Retour'} />
      </FlexWrapper>
      <FlexWrapper
        flexDirections={'column'}
        alignItems={'center'}
        paddings={'1rem'}
        style={{
          borderRadius: '.25rem',
          backgroundColor: PrimaryColors.WhiteColor
        }}
      >
        <Text text={seance.name} margins={'0 0 1rem 0'} />
        <Text text={seance.description.detail} margins={'0 0 1rem 0'} />
        {(!onGoing || seance.id !== liveSeance.id) &&
          <VideoEmbedPlayer videoURL={seance.description.video} />
        }
        {!!onGoing && seance.id === liveSeance.id &&
          <VideoEmbedPlayer videoURL={liveUrl} />
        }
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default Lives;
