import React, { useCallback, useEffect, useState } from 'react';
import { createNotification } from "./components/advanced/toastMessage/ToastManager";

let ORIGIN = window.location.origin;
if (!ORIGIN) {
	ORIGIN = window.location.protocol +
		'//' +
		window.location.hostname +
		(window.location.port ? ':' + window.location.port : '');
}

const VersionChecker = ({ ...props }) => {
	const [actual, actualSet] = useState(false);
	const [mustUpdate, mustUpdateSet] = useState(false);

	const getVersion = useCallback(() => {

		if ( mustUpdate )
			return;

		return fetch(`${ORIGIN}/version.json`)
		.then(res => {
			if (res.ok) return res.json();
			console.error(
				'[VersionChecker]',
				'Cannot get version, status is:',
				res.status
			);
			return false;
		})
		.then(json => {
			if (!json) return;

			if( !actual ) {
				actualSet(json.version);
				return;
			}

			let mustUpgrade = actual !== json.version;
			if (mustUpgrade) createNotification("Mise à jour disponible", {message: "Recharger la page pour appliquer", duration: 0, type: 'info'});
			console.debug(
				'[VersionChecker]',
				'Initial version:',
				props.initial,
				'Actual version:',
				json.version
			);
			mustUpdateSet(mustUpgrade);
		})
		.catch(e => {
			console.error('[VersionChecker]', 'Cannot get version', e);
		});
	}, [ props.initial, mustUpdate, mustUpdateSet, actual, actualSet ]);

	useEffect(() => {
		getVersion();
		const intervalId = setInterval( getVersion, props.delay);
		return () => {
			clearInterval(intervalId);
		};
	}, [getVersion, props.delay]);

	return (
		<div className="version-checker" value={props.initial}>
			{props.children}
		</div>
	);
};

export default VersionChecker;
