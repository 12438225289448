import React, { useState, useEffect } from 'react';
import moment from "moment";
import 'moment/locale/fr';

import { useSelectedClub } from "src/ClubSelect";
import apiMobile from "../../apiMobile";
import { createNotification } from "src/components/advanced/toastMessage/ToastManager";
import MultiSelect from "../../components/commons/multiSelect/MultiSelect";
import FlexWrapper from "../../components/commons/flexWrapper/FlexWrapper";
import ViewCard from "../../components/viewCard/ViewCard";
import { PrevNextButton } from './Planning.style';
import Activity from "src/components/advanced/activity/ActivityItem";

const Planning = () => {
  const [ selectedClub ] = useSelectedClub();
  const [ seances, setSeances ] = useState(null);
  const [ bookings, setBookings ] = useState([]);
  const [ currentDate, setCurrentDate ] = useState(moment().toISOString());
  const [ selectedRoom, setSelectedRoom ] = useState("*");
  const [ selectedCours, setSelectedCours ] = useState("*");

  const loadSeances = () => {
    setSeances(null);
    const [querySeance, controllerSeance] = apiMobile.seance.cSearch({
      clubs: selectedClub.id,
      date_min: moment(currentDate).startOf('day').toDate(),
      date_max: moment(currentDate).endOf('day').toDate(),
    });
    const [queryBooking, controllerBooking] = apiMobile.booking.cSearch({});
    Promise.all([querySeance, queryBooking])
    .then(([ seances, bookings ]) => {
      setSeances(seances);
      setBookings(bookings);
    })
    .catch(err => {
      if(!(err instanceof Error) || err.name !== 'AbortError'){
        console.error(err);
        setSeances([]);
        createNotification('Erreur', { type: 'danger', message: err.userMsg || "Une erreur est survenue" });
      }
    });
    return () => {
      controllerSeance.abort();
      controllerBooking.abort();
    }
  };

  useEffect(loadSeances, [selectedClub.id, currentDate.valueOf()]);

  const reload = () => loadSeances();
  const roomChange = (value) => setSelectedRoom(value.value);
  const coursChange = (value) => setSelectedCours(value.value);
  const changeDate = (value) => {
    setCurrentDate(moment(currentDate).add(value, 'day').toISOString());
  }


  return (
    <>
      <FlexWrapper flexDirections={'column'} justifyContent={'space-between'} alignItems={'center'}>
        <MultiSelector
          name={'room'}
          choices={selectedClub.rooms.map(r => ({ value: r.id, display: r.name }))}
          selected={selectedRoom}
          onChange={roomChange}
          allText={'Toutes les salles'}
        />
        <MultiSelector
          name={'cours'}
          choices={(seances || []).map(s => ({ value: s.name, display: s.name }))}
          selected={selectedCours}
          onChange={coursChange}
          allText={'Tous les cours'}
        />
      </FlexWrapper>
      <FlexWrapper justifyContent={'space-between'} alignItems={'center'} margins={'.25rem 0 1rem 0'}>
        <PrevNextButton
          title={'<'}
          onClick={changeDate.bind(this, -1)}
        />
        <p>{moment(currentDate).format("dddd LL")}</p>
        <PrevNextButton
          title={'>'}
          onClick={changeDate.bind(this, +1)}
        />
      </FlexWrapper>
      {
        !Array.isArray(seances)
        ? <FlexWrapper justifyContent="center" margins="3rem"><Activity type="PulseLoader"/></FlexWrapper>
        : (
          <PlanningList
            seances={seances}
            bookings={bookings}
            rooms={selectedRoom}
            cours={selectedCours}
            date={currentDate}
            reload={reload}
          />
        )
      }
    </>
  );
};

const MultiSelector = ({ name, choices, selected, onChange, allText }) => {
  const _choices = [];
  if (!choices) return null;
  else choices.forEach(c => _choices.findIndex(f => f.value === c.value) < 0 ? _choices.push(c) : null);

  const _onChange = (value) => {
    onChange({
      ...value,
      value: value.value.map(r => r.value).join(',')
    });
  };
  _choices.unshift({ value: '*', display: allText });
  const field = {
    name: name,
    value: selected.split(',').map(s => ({value: s, label: s === '*' ? allText : s })),
    choices: _choices,
    widthAuto: true,
    margins: '.25rem 0',
    stretch: true,
    withDefault: true,
  };
  return <MultiSelect onChange={_onChange} field={field} />;
};

const PlanningList = ({ seances, bookings, rooms, cours, date, reload }) => {
  const [ seanceList, setSeanceList ] = useState(seances);

  const isBooked = (seance) => bookings.findIndex(b => (b.seance.id === seance.id && !b.waiting));
  const filterByDate = (seance) => {
    if (seance.starts.split('T')[0] === date.split('T')[0]) return seance;
  };
  const filterByRoom = (seance) => {
    if (rooms.split(',').indexOf(seance.room.id) >= 0 || rooms.split(',').indexOf('*') >= 0) return seance;
  };
  const filterByCours = (seance) => {
    if (cours.split(',').indexOf(seance.name) >= 0 || cours.split(',').indexOf('*') >= 0) return seance;
  };

  useEffect(() => {
    setSeanceList(
      seances.filter(filterByDate).filter(filterByRoom).filter(filterByCours)
    );
  }, [ rooms, cours, date ]);

  const bookingManager = (seance) => {
    const p = [];
    const index = isBooked(seance);
    if (index < 0) {
      p.push(
        apiMobile.booking.create({
          seance: {
              id: seance.id,
              provider: seance.provider,
              club: seance.club?.id,
              ccClubId: seance?.ccClub?.id
          },
          waiting: false
        })
      );
    } else {
      p.push(
        apiMobile.booking.delete(bookings[index].id, {seanceBooking:  bookings[index]})
      );
    }

    Promise.all(p)
    .then(() => {
      createNotification('', {
        message: `Votre ${index < 0 ? "réservation" : "annulation"} a bien été acceptée`
      });
      reload();
    })
    .catch(err => {
      createNotification('Erreur', {
        type: 'danger',
        message: err.userMsg || "Une erreur est survenue"
      });
    });
  };

  return (
    <>
      { !!seanceList.length && seanceList.map(s => ( <ViewCard
        key={s.id}
        data={s}
        onClick={ !!s.bookable.enabled ? bookingManager.bind(this, s) : null}
        clickText={ isBooked(s) < 0 ? "Réserver" : "Annuler" }
      /> ))}
      { seanceList.length <= 0 && <ViewCard
        data={{ name: "Il n'y a aucun cours correspondant à votre recherche !" }}
        withOutPicture
      /> }
    </>
  );
};

export default Planning;
