import React, { useRef } from "react";
import YouTube from "react-youtube";
import ReactHlsPlayer from "react-hls-player";

import { IframeWrapper } from "./VideoEmbedPlayer.style";
import {videoLinkParser} from "src/utils/videoURL";
import PropTypes from "prop-types";

const VideoEmbedPlayer = ({ videoURL, opts = {} }) => {
  const playerRef = useRef();
  const options = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 0,
    },
    ...opts,
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };

  let videoLinkData = videoLinkParser(videoURL);
  if (!videoLinkData && typeof videoURL === 'string' && videoURL.trim().length > 0) {
    videoLinkData ={
      type: 'other',
      videoId: videoURL,
      embedURL: videoURL,
    };
  }
  if (!videoLinkData) {
    return null;
  }
  if (videoLinkData.type === 'youtube') {
    return (
      <IframeWrapper>
        <YouTube videoId={videoLinkData.videoId} opts={options} onReady={_onReady} />
      </IframeWrapper>
    );
  }

  if (videoLinkData.type === 'vimeo') {
    return (
      <IframeWrapper>
        <iframe title="VideoEmbedPlayer" src={videoLinkData.embedURL}/>
      </IframeWrapper>
    );
  }

  if (videoLinkData.type === 'live') {
    return (
      <ReactHlsPlayer
        playerRef={playerRef}
        src={ videoLinkData.embedURL }
        width={'100%'}
        controls={true}
        autoPlay={true}
      />
    );
  }

  if (videoLinkData.type === 'other') {
    return (
      <video style={{ padding: '1rem 0', width: '100%' }} controls>
        <source src={videoLinkData.embedURL} type={'video/mp4'} />
      </video>
    );
  }

  return null;
};

VideoEmbedPlayer.propTypes = {
  videoURL: PropTypes.string,
};

VideoEmbedPlayer.defaultProps = {
};

export default VideoEmbedPlayer;
