import React from 'react';
import PropTypes from 'prop-types';
import {ClipLoader, PulseLoader, SquareLoader} from "react-spinners";

const _ = {
  components: {
    "ClipLoader": ClipLoader,
    "PulseLoader": PulseLoader,
    "SquareLoader": SquareLoader,
  }
}

function ActivityItem({type, ...props}) {
  const Component = _.components[type];
  return <Component {...props}/>
}

ActivityItem.propTypes = {
  type: PropTypes.oneOf(["ClipLoader", "PulseLoader", "SquareLoader"]),
};

ActivityItem.defaultProps = {
  type: "ClipLoader"
}

export default ActivityItem;
