import React from 'react';
import PropTypes from 'prop-types'

import {StyledLabel, StyledInput} from './Input.style'

const Input = ({validator, onChange, optional, field, key}) => {

	const _onChange = (e) => {
		let { name, value, checked } = e.target;

		if (field.type === 'checkbox') value = checked;

		var v;
		if (field.optional && !value) v = { value, valid : true };
		else if (validator && validator.typing)  v = validator.typing(value);
		else v = { value, valid : true }

		v.name = name;
		v.event = e;
		onChange(v);
	};

	const _onBlur = (e) => {
		let { name, value, checked } = e.target;

		if (field.type === 'checkbox') value = checked;

		var v;
		if (field.optional && !value) v = { value, valid : true };
		else if (validator && validator.typing)  v = validator.typing(value);
		else v = { value, valid : true }
		
		v.name = name;
		v.event = e;
		onChange(v);
	};

	if ( field.hidden ) {
		return null;
	}
	
	return (
		<StyledLabel 
			stretch={field.stretch}
			margins={field.margins}
		>
			{field.label}
			<StyledInput
				disabled={field.disabled}
				stretch={field.stretch}
				type={field.type}
				name={field.name}
				placeholder={field.placeholder}
				value={field.value === null || typeof field.value === 'undefined' ? '' : field.value}
				checked={field.value === null || typeof field.value === 'undefined' ? false : field.value}
				onBlur = { _onBlur }
				onChange={ _onChange }
			/>
		</StyledLabel>
	);
};

Input.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	inputType: PropTypes.string,
	name: PropTypes.string,
	stretch: PropTypes.bool,
	value: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	optional: PropTypes.bool,
	margins: PropTypes.string,
};

Input.defaultProps = {
	inputType: 'text'
};

export default Input;
