

export function normalize(s) {
  if (typeof s !== 'string') {
    return s;
  }
  return s
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function compareOrder(a, b) {
  if (typeof a !== 'string') {
    return 1;
  } else if (typeof b !== 'string') {
    return -1;
  } else {
    return normalize(a).localeCompare(normalize(b));
  }
}

export function fillString(s, argTab, separator = '§') {
  if (!Array.isArray(argTab)) {
    return fillString(s, [argTab], separator);
  }
  return s.split(separator).flatMap((sPart, index) => [sPart, argTab[index]], '').join('');
}
