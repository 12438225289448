import ToastRoot, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

function createNotification(title = ' ', {message = ' ', type = 'success', duration=5000} = {}) {
  return store.addNotification({
    title, message, type,
    insert: "bottom",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: duration,
      onScreen: true,
      pauseOnHover: true,
    }
  });
}

export {ToastRoot, createNotification};
