import moment from 'moment';
import React, {useEffect, useState} from 'react';
import apiMobile from 'src/apiMobile';
import {createNotification} from 'src/components/advanced/toastMessage/ToastManager';
import Button from 'src/components/commons/button/Button';
import FlexWrapper from 'src/components/commons/flexWrapper/FlexWrapper';
import H6 from 'src/components/commons/h6/H6';
import Input from 'src/components/commons/input/Input';
import Modal from 'src/components/commons/modal/Modal';
import Loader from 'src/Loader';
import {useSession} from 'src/Session';
import {genderToLabel} from 'src/utils/genderLegacy';
import {fillString} from 'src/utils/string';

const _ = {
  text: {
    modalTitle: "Souhaitez-vous vraiment supprimer votre compte de manière définitive ?",
    mainWarning: "En demandant la suppression de vos données personnelles, toutes les données seront supprimées de manière définitive.",
    secondWarning: "Les comptes et réservations créés via un les plateformes tierces ne sont pas concernés. Rapprochez-vous de votre club pour connaître les démarches à suivre.",
    accountWarningLabel: "Les données de votre compte au nom de § § seront supprimées sans aucune possibilité de les récupérer.",
    bookingsWarningLabel: "§ réservation(s) seront annulées",
    clubsWarningLabel: "Vous serez supprimé de § club",
    deleteAccountPopupText: "Par sécurité, veuillez taper l’adresse e-mail de votre compte afin de procéder à sa suppression.",
    accountDeleteDone: "Les données de votre compte sont supprimées sans aucune possibilité de les récupérer.",
  }
}

const ModalAccountDelete = ({onClose}) => {
  const [ , signOut ] = useSession();
  const [accountData, setAccountData] = useState(null);
  const [email, setEmail] = useState('');


  useEffect(() => {
    const [queryAthlete, controllerAthlete] = apiMobile.athlete.cGet('me');
    const [queryBooking, controllerBooking] = apiMobile.booking.cSearch({});
    Promise.all([queryAthlete, queryBooking])
      .then(([accountSupplied,bookingSupplied]) => {
        const now = moment();
        setAccountData({
          email: accountSupplied.email,
          firstname: accountSupplied.firstname,
          lastname: accountSupplied.lastname,
          clubCount: accountSupplied.clubs.length,
          bookingCount: bookingSupplied.filter((b) => (now.isBefore(b?.seance?.starts))).length,
        });
      })
      .catch(() => {});
    return () => {
      controllerAthlete.abort();
      controllerBooking.abort();
    };
  }, []);

  if (accountData === null) {
    return <Loader/>
  }

  const handleEmailChange = ({value}) => {
    setEmail(value)
  }

  const handleDelete = () => {
    if(email !== accountData.email){
      return;
    }
    apiMobile.athlete.delete('/me', {email})
      .finally(() => {
        createNotification("Compte supprimé avec succès", { message: _.text.accountDeleteDone, duration: 0});
        signOut();
      })
  }

  return (
    <Modal modalTitle={_.text.modalTitle} onClick={onClose}>
      <FlexWrapper flexDirections="column" gap="2rem" margins="1rem">
        <H6 text={_.text.mainWarning}/>
        <H6 text={_.text.secondWarning}/>
        <div>{fillString(_.text.accountWarningLabel, [accountData.firstname, accountData.lastname])}</div>
        <div>{fillString(_.text.bookingsWarningLabel, [accountData.bookingCount])}</div>
        <div>{fillString(_.text.clubsWarningLabel, [accountData.clubCount])}</div>
        <div>{_.text.deleteAccountPopupText}</div>
        <Input onChange={handleEmailChange} field={{stretch: true, label: 'E-mail de mon compte', value: email}}/>
        <Button
          theme="alert" title="Supprimer définitivement mon compte et toutes mes données associées"
          onClick={handleDelete} disabled={email !== accountData.email}
        />
      </FlexWrapper>
    </Modal>
  );
};

export default ModalAccountDelete;
