import React from 'react';
import { MenuLink, MenuWrapper } from "./MenuTab.style";

const MenuTab = ({ match, links, margins }) => {
	return (
		<MenuWrapper
			flexDirections={'row'}
			justifyContent={'space-around'}
			alignItems={'center'}
			margins={ margins }
			wrapperWidth={'100%'}
		>
			{ links.map((l, i) => <MenuLink key={ i } to={`${match.url}/${l.url}`}>{l.display}</MenuLink>) }
		</MenuWrapper>
	);
};
export default MenuTab;
