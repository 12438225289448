import styled from "styled-components";

import { PrimaryColors, primaryColorsAlpha } from "../Theme";

export const ModalWrapper = styled.div`
  background-color: ${PrimaryColors.MainLightColor};
  position: fixed;
  top: 0;
  right: 0;
  width: 40vw;
  z-index: 99;
  height: 100%;
  overflow: auto;
  box-shadow: -0.25rem -0.25rem 0.75rem 0.25rem ${primaryColorsAlpha.MainDarkColor16};
`

export const CloseWrapper = styled.button`
  cursor: pointer;
  border: none;
  appearance: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }
`;
