import React, { useState } from 'react';
import { useContextVideo } from "../../components/context/VideoContext";
import FlexWrapper from "../../components/commons/flexWrapper/FlexWrapper";
import Button from "../../components/commons/button/Button";
import Text from "../../components/commons/text/Text";
import { PrimaryColors } from "../../components/commons/Theme";
import VideoEmbedPlayer from "../../components/advanced/embdedVideo/VideoEmbedPlayer";
import Image from "../../Image";
import liveVideoImage from '../../assets/images/vod/liveVideoImage.jpg';
import lesMillsImage from '../../assets/images/vod/lesMills.jpg';
import defaultVideoImage from '../../assets/images/vod/defaultVideoImage.jpeg';
import { Link } from "react-router-dom";

const Streaming = ({ match }) => {
  const liveUrl = match.path.replace('streaming', 'lives');
  const [ videos, library ] = useContextVideo();
  const [ selectedLibrary, setSelectedLibrary ] = useState(false);

  const excludeLive = (f) => f.name !== 'live';
  const selectLibrary = (value) => setSelectedLibrary(value);

  if (!library.length || library.length <= 0) return (
    <div>
      Aucune vidéo disponible
    </div>
  );

  if (!!selectedLibrary) return (
    <MainLibraryView
      videos={videos}
      library={library}
      selected={selectedLibrary}
      back={() => setSelectedLibrary(false)}
    />
  );

  return (
    <FlexWrapper flexDirections={'column'} margins={'1rem'}>
      { library.filter(excludeLive).map((c, i) => (
        <FlexWrapper
          key={i}
          style={{
            backgroundColor: PrimaryColors.WhiteColor,
            borderRadius: '.25rem',
            textAlign: 'center',
          }}
          flexDirections={'row'}
          alignItems={'center'}
          onClick={selectLibrary.bind(this, c.name)}
          margins={'0 0 1rem 0'}
        >
          <div style={{ width: '50%' }}>
            <Image src={
              c.coverPicture === "lesMills" ? lesMillsImage :
              c.coverPicture === "liveCover" ? liveVideoImage : defaultVideoImage
            } alt={`${c.name}_picture`} />
          </div>
          <Text text={c.title} margins={'0 1rem'} />
        </FlexWrapper>)
      ) }
      { library.filter(f => f.name === "live").map((l, i) =>
        <Link key={i} to={liveUrl} style={{
          display: 'flex',
          flexDirections: 'row',
          alignItems: 'center',
          backgroundColor: PrimaryColors.WhiteColor,
          borderRadius: '.25rem',
          textAlign: 'center',
          margin: '0 0 1rem 0',
          textDecoration: 'none'
        }}>
          <div style={{ width: '50%' }}>
            <Image src={liveVideoImage} alt={`${l.name}_picture`} />
          </div>
          <Text text={l.title} margins={'0 1rem'}/>
        </Link>
      ) }
    </FlexWrapper>
  );
};

const MainLibraryView = ({ videos, library, selected, back }) => {
  const [ selectedCategory, setSelectedCategory ] = useState(false);
  const [ selectedVideo, setSelectedVideo ] = useState(false);

  const backToCategory = () => setSelectedCategory(false);
  const backToVideo = () => setSelectedVideo(false);
  const libraryIndex = library.findIndex(l => l.name === selected);

  return (<FlexWrapper flexDirections={'column'}>
    {!selectedCategory && !selectedVideo && <FlexWrapper margins={'.5rem'}>
      <Button onClick={back} title={"Retour aux collection"} theme={'secondary'}/>
    </FlexWrapper>}
    {!!selectedCategory && !selectedVideo && <FlexWrapper margins={'.5rem'}>
      <Button onClick={backToCategory} title={"Retour au categorie"} theme={'secondary'}/>
    </FlexWrapper>}
    {!!selectedVideo && <FlexWrapper margins={'.5rem'}>
      <Button onClick={backToVideo} title={"Retour aux videos"} theme={'secondary'}/>
    </FlexWrapper>}

    { ["vimeo", "lesMills"].indexOf(library[libraryIndex].name) >= 0 &&
      <LibraryCategoryView library={videos[libraryIndex]}
                           selectedCategory={selectedCategory}
                           setSelectedCategory={setSelectedCategory}
                           selectedVideo={selectedVideo}
                           setSelectedVideo={setSelectedVideo}
      />
    }
    { ["clubVOD"].indexOf(library[libraryIndex].name) >= 0 &&
      <LibraryView library={videos[libraryIndex]} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
    }
  </FlexWrapper>);
};

const LibraryCategoryView = ({ library, selectedCategory, setSelectedCategory, selectedVideo, setSelectedVideo }) => {

  const selectLibrary = (value) => setSelectedCategory(value);

  if (!!selectedCategory) return (
    <LibraryView library={selectedCategory} selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo} />
  );

  return (
    <FlexWrapper flexDirections={'column'} margins={'1rem'}>
      { !!library && library.map((c, i) => (
        <FlexWrapper
          key={i}
          style={{
            backgroundColor: PrimaryColors.WhiteColor,
            padding: '1rem',
            borderRadius: '.25rem',
            textAlign: 'center',
          }}
          onClick={selectLibrary.bind(this, c)}
          margins={'1rem'}
        >
          { !!c.firstVideoPictureLink && <div style={{ width: '5rem', marginRight: '.5rem' }}>
            <Image src={c.firstVideoPictureLink} alt={`${c.id}_${c.name}_picture`} className={'roundedPicture'} />
          </div> }
          <Text text={c.name} />
        </FlexWrapper>)
      ) }
    </FlexWrapper>
  );
};

const LibraryView = ({ library, selectedVideo, setSelectedVideo }) => {
  const selectVideos = (value) => setSelectedVideo(value);

  if (!!selectedVideo) return (<VideoView video={selectedVideo} />);

  return (
    <div>
      { !!library.videos && library.videos.map((v, i) => (
        <FlexWrapper
          key={i}
          style={{
            backgroundColor: PrimaryColors.WhiteColor,
            padding: '1rem',
            borderRadius: '.25rem',
            textAlign: 'center',
          }}
          onClick={selectVideos.bind(this, v)}
          margins={'1rem'}
        >
          { !!v.pictureHd && <div style={{ width: '5rem', marginRight: '.5rem' }}>
            <Image src={v.pictureHd} alt={`${v.id}_${v.name}_picture`} className={'roundedPicture'} />
          </div> }
          <Text text={v.name} />
        </FlexWrapper>
      ) ) }
      { !!library && !library.videos && library.map((v, i) => (
        <FlexWrapper
          key={i}
          style={{
            backgroundColor: PrimaryColors.WhiteColor,
            padding: '1rem',
            borderRadius: '.25rem',
            textAlign: 'center',
          }}
          onClick={selectVideos.bind(this, v)}
          margins={'1rem'}
        >
          { !!v.pictureHd && <div style={{ width: '5rem', marginRight: '.5rem' }}>
            <Image src={v.pictureHd} alt={`${v.id}_${v.name}_picture`} className={'roundedPicture'} />
          </div> }
          <Text text={v.name} />
        </FlexWrapper>
      ) ) }

    </div>
  );
};

const VideoView = ({ video }) => {
  return (
    <FlexWrapper
      flexDirections={'column'}
      alignItems={'center'}
      style={{
        backgroundColor: PrimaryColors.WhiteColor,
        borderRadius: '.25rem',
        padding: '.5rem 1rem'
      }}
    >
      <Text text={video.name} margins={'.25rem'} textWeight={'700'} />
      { !!video.description && <Text text={video.description} margins={'.25rem'} /> }
      { !!video.difficulty && !!video.category && <Text text={`${video.category} ${video.difficulty}`} margins={'.25rem 0 1rem'} /> }
      { !!video.videoLink && <VideoEmbedPlayer videoURL={video.videoLink} /> }
      { !!video.videoLink && !!video.difficulty && !!video.category && <VideoEmbedPlayer videoURL={video.videoLink} /> }
    </FlexWrapper>
  );
};

export default Streaming;
