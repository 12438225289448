import styled from 'styled-components'

export const Paragraph = styled.p`
    color: ${({textColor, delayColor}) => delayColor ? delayColor : textColor};
    font-weight: ${({textWeight}) => textWeight};
    font-size: ${({textSize}) => textSize};
    margin: ${({margins}) => margins};
    display: ${({icon}) => icon ? 'flex' : ''};
    white-space: ${({preWrap}) => preWrap ? 'pre-wrap' : ''};

    &:before {
        content: ${({timeDisplay}) => timeDisplay ? '""' : ''};
        height: .75rem;
        width: .75rem;
        border-radius: 50%;
        display: inline-block;
        background-color: ${({delayColor}) => delayColor || ''};
        margin-right: .5rem;
    }
`

export const Icon = styled.img`
    margin-right: .5rem;
`