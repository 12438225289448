import React from "react";
import PropTypes from "prop-types";

import { StyledButton, SearchImg, IconBeforeMargins } from "./Button.style";
import SearchIcon from "../../../assets/icons/ic_search.svg";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ReloadIcon from "../../advanced/icons/ReloadIcon";

const Button = ({
  type,
  title,
  disabled,
  isLoading,
  onClick,
  theme,
  textTransform,
  margins,
  stretch,
  imgIcon,
  variantColor,
  value,
  iconBefore,
  backIcon,
  iconBeforeMargins,
  style
}) => {
  const _onClick = (e) => {
    e.preventDefault();
    onClick && onClick(e);
  };

  return (
    <StyledButton
      type={type}
      disabled={!!disabled}
      onClick={_onClick}
      theme={theme}
      textTransform={textTransform}
      margins={margins}
      stretch={stretch}
      imgIcon={imgIcon}
      variantColor={variantColor}
      value={value}
      style={style}
      iconBefore={iconBefore}
    >
      {backIcon && <ArrowBackIosIcon /> }
      {iconBefore && (
        <IconBeforeMargins
          iconBeforeMargins={iconBeforeMargins}
          src={iconBefore}
          alt=""
        />
      )}
      {isLoading ? <ReloadIcon/> : title}
      {imgIcon && <SearchImg src={SearchIcon} alt="" />}
    </StyledButton>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf([
    "primary",
    "search",
    "rounded",
    "textOnly",
    "secondary",
    "alert",
    "danger",
    "success",
    "filled",
    "filledDanger",
  ]),
  margins: PropTypes.string,
  stretch: PropTypes.bool,
  imgIcon: PropTypes.bool,
  variantColor: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  disabled: false,
  isLoading: false,
  theme: "primary",
};

export default Button;
