import styled from "styled-components";
import Button from "../../components/commons/button/Button";

import { PrimaryColors } from "../../components/commons/Theme";

export const PrevNextButton = styled(Button)`
  text-decoration: none;
  color: inherit;
  font-weight: bolder;
  border: 0.0625rem solid ${PrimaryColors.MainDarkColor};
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0.25rem;
  letter-spacing: 0.06rem;
  transition: all 0.25s linear;

  &:hover {
    opacity: 0.6;
  }

  &:first-child {
    margin-right: 1rem;
  }
`;
