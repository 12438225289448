import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { primaryColorsAlpha } from "../Theme";
import {StyledLabel} from './MultiSelect.style';

const customStyles = {
	menu: (provided, state) => ({
		...provided,
		zIndex: 2,
	}),
  control: () => ({
    border: `0.0625rem solid ${primaryColorsAlpha.MainDarkColor08}`,
		display: 'flex',
		borderRadius: "0.25rem",
		padding: ".25rem .5rem"
  }),
	indicatorSeparator: () => ({
		display: 'none'
	}),
	placeholder: () => ({
		fontWeight: 'normal',
		color: primaryColorsAlpha.MainDarkColor56
	}),
	multiValueLabel: () => ({
		background: "rgba(6, 86, 119, 0.02)"
	})
}

const MultiSelect = ({key, onChange, field, readOnly = false}) => {
	const _onChange = (e) => {
		if (!!field.withDefault) {
			if (e.length <= 0) e = [ field.choices.find(c => c.value === '*') ];
		}
		onChange({ name: field.name, value: e, valid: true, event: e });
	};

	return (
		<StyledLabel
			stretch={field.stretch}
			margins={field.margins}
		>
			{field.label}
			<Select
				name={field.name}
				onChange={_onChange}
				value={field.value}
				options={
						field.choices.filter(c => c.value === field.value || !readOnly)
						.map(m => ({ value: m.value, label: m.display }))
				}
				closeMenuOnSelect={true}
				isMulti
				styles={customStyles}
				placeholder="Sélectionner"
			/>
		</StyledLabel>
	)
}

MultiSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.array]),
    onChange: PropTypes.func.isRequired,
    stretch: PropTypes.bool,
    margins: PropTypes.string,
    name: PropTypes.string,
};

export default MultiSelect;
