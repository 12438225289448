import React, { useState, useEffect } from 'react';
import moment from 'moment';

import FlexWrapper from "../../components/commons/flexWrapper/FlexWrapper";
import apiMobile from "../../apiMobile";
import { createNotification } from "../../components/advanced/toastMessage/ToastManager";
import Text from "../../components/commons/text/Text";
import ViewCard from "../../components/viewCard/ViewCard";
import { useSelectedClub } from "../../ClubSelect";

const BookingList = () => {
  const [ selectedClub ] = useSelectedClub();
  const now = moment();
  const [ state, setState ] = useState({ step: "INIT", current: [], old: [] });

  const filterByClub = (book) => book.club.id === selectedClub.id;
  const filterByActive = (book) => moment(book.seance.dates.ends.iso).valueOf() >= now.valueOf();
  const filterByExpired = (book) => moment(book.seance.dates.ends.iso).valueOf() < now.valueOf();
  const formatData = (book) => {
    return {
      id: book.id,
      name: book.seance.name,
      starts: book.seance.dates.starts.iso,
      ends: book.seance.dates.ends.iso,
      waiting: book.waiting
    };
  };

  const load = () => {
    apiMobile.booking.search({})
    .then(booking => {
      setState({
        step: "OK",
        current: booking.filter(filterByClub).filter(filterByActive).map(formatData),
        old: booking.filter(filterByClub).filter(filterByExpired).map(formatData)
      });
    })
    .catch(err => {
      console.error(err);
      setState({ step: "ERROR", current: [], old: [] });
      createNotification("Erreur", { type: 'danger', message: err.userMsg || "Une erreur est survenue"});
    });
  };

  useEffect(() => {
    if (["ERROR", "OK", "LOADING"].indexOf(state.step) >= 0) return ;
    setState({ step: "LOADING", current: [], old: [] });
    load();
  }, [ state.step ]);

  const cancelBooking = (booking) => {
    apiMobile.booking.delete(booking.id)
    .then(() => {
      createNotification(`Votre annulation a bien été prise en compte`);
      setState({ step: "RELOAD", old: [], current: [] });
    })
    .catch(err => {
      createNotification('Erreur', {
        type: 'danger',
        message: err.userMsg || "Une erreur est survenue"
      });
    });
  };

  return (
    <FlexWrapper margins={'1rem 0'} flexDirections={'column'} alignItems={'center'} justifyContent={'space-between'}>
      <FlexWrapper flexDirections={'column'} wrapperWidth={'100%'}>
        <Text text={"À venir"} />
        { state.current.map(b => <ViewCard key={b.id} data={b} withOutPicture onClick={cancelBooking.bind(this, b)} clickText={"Annuler"} />)}
        { !state.current.length && <ViewCard data={{ name: "Aucune séance à venir" }} withOutPicture />}
      </FlexWrapper>

      <FlexWrapper flexDirections={'column'} wrapperWidth={'100%'}>
        <Text text={"Passées"} />
        { state.old.map(b => <ViewCard key={b.id} data={b} withOutPicture />)}
        { !state.old.length && <ViewCard data={{ name: "Aucune séance passées" }} withOutPicture />}
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default BookingList;
