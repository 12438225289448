import React from "react";

import { useSession } from "../../../Session";
import Button from "../../commons/button/Button";
import FlexWrapper from "../../commons/flexWrapper/FlexWrapper";
import Text from "../../commons/text/Text";
import { PrimaryColors } from "../Theme";
import { useContextLayout } from "../../context/LayoutContext";

const extractUserInitials = (user) => {
  return `${user.firstname}`.charAt(0) + `${user.lastname}`.charAt(0);
};

const UserAvatar = () => {
  const { isDeviceMobile } = useContextLayout();
  const [{ user }] = useSession();

  return (
    <FlexWrapper alignItems="center">
      { !isDeviceMobile && <Text
        text={`${user.firstname} ${user.lastname}`}
        textColor={PrimaryColors.MainDarkColor}
      />}
      <Button
        theme="rounded"
        style={{ cursor: 'default' }}
        title={extractUserInitials(user)}
        margins="0 0 0 .5rem"
      />
    </FlexWrapper>
  );
};

export default UserAvatar;
