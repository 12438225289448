import React from "react";
import PropTypes from "prop-types";

import {
  SideBarWrapper,
  NavGroup,
  NavHeader,
  StyledLink,
  StyledButton
} from "./SideBar.style";

import EventIcon from "@material-ui/icons/Event";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import PersonIcon from '@material-ui/icons/Person';
import { useSession } from "../../../Session";
import { useContextVideo } from "../../context/VideoContext";

const Icon = ({ link, ...props }) => {
  switch (link) {
    case "/planning":
      return <EventIcon {...props} />;
    case "/booking":
      return <BookmarksIcon {...props} />;
    case "/lives":
      return <VideocamIcon {...props} />;
    case "/streaming":
      return <VideoLibraryIcon {...props} />;
    case "/account":
      return <PersonIcon {...props} />;
    default:
      return null;
  }
};
const SideBar = ({ isDeviceMobile, isOpen, baseUrl }) => {
  const [ , signOut ] = useSession();
  const [ , library ] = useContextVideo();

  const linked = [
    {
      sectionTitle: "Mon club",
      items: [
        { url: "/planning", name: "Planning" },
        { url: "/booking", name: "Réservations" },
      ],
    },
  ];

  if (!!library) {
    const items = [];
    if (library.findIndex(f => f.name === 'live') >= 0) items.push({ url: "/lives", name: "Planning des lives" });
    if (!!library.length) items.push({ url: "/streaming", name: "Vidéo à la demande" });

    linked.push({
      sectionTitle: "Streaming",
      items: items
    });
  }

  linked.push({
    sectionTitle: "Compte",
    items: [
      { url: "/account", name: "Mon compte" },
    ]
  });

  const display = (section, key) => {
    const items = section.items.map((i, j) => {
      return (
        <StyledLink key={j} to={`${baseUrl}${i.url}`}>
          <Icon link={i.url} style={{ margin: "0 .5rem 0" }} />
          {i.name}
        </StyledLink>
      );
    }).filter(e=>!!e);
    if (!items.length) return null;
    return (
      <NavGroup key={key}>
        { !!section.sectionTitle && <NavHeader>{section.sectionTitle}</NavHeader>}
        {items}
      </NavGroup>
    );
  }

  return (
    <SideBarWrapper isDeviceMobile={isDeviceMobile} isExpanded={isOpen}>
      { linked.map((l,i) => display(l, i) )}
      <StyledButton onClick={ signOut }>Déconnexion</StyledButton>
    </SideBarWrapper>
  );
};

SideBar.propTypes = {
  isDeviceMobile: PropTypes.bool,
  isOpen: PropTypes.bool,
};

export default SideBar;
