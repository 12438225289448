import React from "react";
import moment from 'moment';

import FlexWrapper from "../commons/flexWrapper/FlexWrapper";
import Card from "../commons/card/Card";
import Text from "../commons/text/Text";
import Button from "../commons/button/Button";

const ViewCard = ({
  data,
  withOutPicture,
  className,
  onDetail,
  onClick,
  clickText
}) => {
  const truncate = (str = "", maxLength) => {
    const index = str.indexOf(" ", maxLength);
    return index === -1 ? str : str.substring(0, index) + "...";
  };

  return (
    <Card
      className={className}
      style={{
        minHeight: "7rem",
        display: "flex",
        alignItems: "center",
        position: "relative",
        margin: ".5rem 0"
      }}
    >
      <FlexWrapper
        flexDirections="row"
        alignItems="center"
        wrapperWidth={'100%'}
        onClick={ !!onDetail ? onDetail : () => {} }
        style={{
          cursor: !!onDetail ? 'pointer' : 'default'
        }}
      >
        {!withOutPicture && !!data.display.color && (
          <div
            className={"roundedPicture"}
            style={{ backgroundColor: `#${data.display.color}` }}
          />
        )}
          <FlexWrapper flexDirections="column">
            <Text text={data.name} textWeight="bolder" />
            {!!data.room && (
              <Text text={truncate(`Salle ${data.room.id}`, 30)} textSize="0.875rem" />
            )}
            {!!data.starts && !!data.ends && (
              <Text text={`${moment(data.starts).format('HH:mm')} - ${moment(data.ends).format('HH:mm')}`} textSize="0.875rem" />
            )}
          </FlexWrapper>
      </FlexWrapper>
      { !!onClick && !!clickText &&
        <Button style={{ position: "absolute", right: "1rem", bottom: '1rem', zIndex: 2 }} onClick={ onClick } title={clickText} />
      }
    </Card>
  );
};

export default ViewCard;
