import React from 'react';
import { Redirect, Route, Switch } from "react-router-dom";

import Subscription from "./Subscription";
import BookingList from "src/modules/Booking/BookingList";
import MenuTab from "../../components/commons/menuTab/MenuTab";

const Booking = ({match}) => {
  const menuLink = [
    { url: "booking", display: "Réservation" },
    { url: "subscription", display: "Abonnements/Cartes" },
  ];

  return (
  <>
    <MenuTab match={match} links={menuLink} />
    <Switch>
      <Route exact path={`${match.url}/booking`} component={BookingList} />
      <Route path={`${match.url}/subscription`} component={Subscription} />
      <Redirect from={"*"} to={`${match.url}/booking`} />
    </Switch>
  </>);
};

export default Booking;
