import React, { useState, createContext, useContext, useEffect } from 'react';
import {Redirect} from 'react-router-dom';

import {useUserClubs} from './UserClubs';
import apiMobile from "./apiMobile";

export const Context = createContext();
export const useSelectedClub = () => useContext(Context);

const ClubSelect = ({ children, clubId }) => {
	const clubList = useUserClubs();

	const [ state, stateChange ] = useState('INIT');
	const [ selectedClub, setSelectedClub ] = useState( false );

	const loadClub = (club) => {
		apiMobile.club.get(club.id)
		.then(([detail]) => {
			setSelectedClub(detail);
			stateChange('OK');
		})
	};
	useEffect( () => {
		stateChange('INIT');
		const club = clubList.find( c => c.id === clubId );
		if ( club ){
			loadClub(club);
		} else if ( clubList.length ) {
			setSelectedClub(clubList[0]);
			stateChange('REDIRECT');
		} else {
			setSelectedClub(null);
			stateChange('SORRY');
		}
	}, [clubId]);

	if (state === "INIT")
		return null;

	if ( state === 'REDIRECT' ) {
		return <Redirect to = {`/club/${selectedClub.id}`} />
	}

	if ( state === 'SORRY') {
		//TODO
		return <p>Vous n'avez aucun club</p>;
	}

	return (
		<Context.Provider value={[selectedClub, setSelectedClub, clubList]}>
			{children}
		</Context.Provider>
	);
};

export default ClubSelect;
