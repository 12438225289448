import React from "react";

import {useContextLayout} from "src/components/context/LayoutContext";
import UserAvatar from "../commons/userAvatar/UserAvatar";
import ClubsChooser from "../commons/clubsChooser/ClubsChooser";
import Logo from "../../assets/logos/club-connect-white.svg";
import FlexWrapper from "../commons/flexWrapper/FlexWrapper";
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import { AppHeader, AppBrandWrapper, AppBrand, CcLogo } from "./Header.style";

const Header = ({ baseUrl }) => {
  const {isDeviceMobile, isMenuExpanded, setMenuExpanded} = useContextLayout();

  const handleMenuPress = () => {
    setMenuExpanded(!isMenuExpanded);
  }

  const IconMenu = isMenuExpanded ? MenuOpenIcon : MenuIcon;
  const style = {
    minWidth: !isDeviceMobile ? 'calc(15rem - 1rem)' : 'calc(7rem - 1rem)'
  };
  return (
    <AppHeader>
      <FlexWrapper wrapperHeight="100%">
        {!isDeviceMobile ? null : (
          <div onClick={handleMenuPress} style={{display: 'flex', padding: ".5rem"}}>
            <IconMenu style={{alignSelf: 'center'}}/>
          </div>
        )}
        <AppBrandWrapper to={`${baseUrl}/planning`} id="app-brand-wrapper" style={style}>
          <CcLogo src={Logo} alt="Logo Club Connect" />
          <AppBrand>
            {!isDeviceMobile ? <>Club Connect <span>Members</span></> : <span>Members</span>}
          </AppBrand>
        </AppBrandWrapper>
        <ClubsChooser />
      </FlexWrapper>
      <UserAvatar />
    </AppHeader>
  );
};

export default Header;
