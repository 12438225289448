import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { PrimaryColors, SecondaryColors, primaryColorsAlpha } from "../Theme";

export const SideBarWrapper = styled.nav`
  align-items: stretch;
  background-color: ${SecondaryColors.secondaryColorLightVariant};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 4rem;
  min-width: 15rem;
  ${props => props.isDeviceMobile ? '' : 'max-height: calc(100vh - 4rem)'};
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  transform: translateX(${props => props.isExpanded ? '0' : '-100%'});
  bottom: 0;

  &::-webkit-scrollbar {
    width: .25rem;
    height: .25rem;
  }

  &::-webkit-scrollbar-thumb {
    background: ${primaryColorsAlpha.WhiteColor72};
    border-radius: .25rem;
  }

  &::-webkit-scrollbar-track {
    background: ${SecondaryColors.secondaryColorLighterVariant};
    padding: 0.0625rem;
  }
  
  transition: transform 0.3s ease-out;

`;

export const NavGroup = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
`;

export const NavHeader = styled.h5`
  background-color: ${SecondaryColors.secondaryColorLighterVariant};
  border-top: 0.0625rem solid ${SecondaryColors.secondaryColor};
  border-bottom: 0.0625rem solid ${SecondaryColors.secondaryColor};
  color: ${PrimaryColors.WhiteColor};
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  padding: 0.6rem 1rem;
  text-transform: uppercase;
`;

export const StyledLink = styled(NavLink)`
  color: ${primaryColorsAlpha.WhiteColor72};
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: ${PrimaryColors.WhiteColor};
    background-color: ${SecondaryColors.secondaryColorLighterVariant};
  }

  &.active {
    color: ${PrimaryColors.WhiteColor};
    font-weight: bold;
  }
`;

export const StyledButton = styled.div`
  cursor: pointer;
  color: ${primaryColorsAlpha.WhiteColor72};
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  
  &:hover {
    color: ${PrimaryColors.WhiteColor};
    background-color: ${SecondaryColors.secondaryColorLighterVariant};
  }
`;
