import React, {createContext, useContext, useEffect, useState} from 'react';

export const Context = createContext();
export const useContextLayout = () => useContext(Context);

function isMobileDevice(width){
  const maxMobileDeviceWith = 775;
  return width <= maxMobileDeviceWith;
}

export default function LayoutContext ({children}) {
  const isMobile = isMobileDevice(window.innerWidth);
  const [isDeviceMobile, setDeviceMobile] = useState(isMobile);
  const [isMenuExpanded, setMenuExpanded] = useState(!isMobile);

  const handleWindowSizeChange = () => {
    const isMobile = isMobileDevice(window.innerWidth);
    setDeviceMobile(isMobileDevice(window.innerWidth));
    setMenuExpanded(!isMobile);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <Context.Provider value={{isDeviceMobile, isMenuExpanded, setMenuExpanded}}>
      {children}
    </Context.Provider>
  );
};
