
export function genderToLabel(gender) {
  switch (gender) {
    case 'mixed':
      return 'Mixte';
    case 'female':
      return 'Femme';
    case 'male':
      return 'Homme';
    default:
      console.warn('[GenderLegacy] genderLabel unknown gender', gender);
      return gender;
  }
}

