import React from "react";
import PropTypes from "prop-types";

import { primaryColorsAlpha } from "../Theme";
import { Paragraph, Icon } from "./Text.style";

const Text = ({
  text,
  textColor,
  textWeight,
  textSize,
  margins,
  icon,
  timeDisplay,
  delayColor,
  style,
  preWrap
}) => {
  return (
    <Paragraph
      textColor={textColor}
      textWeight={textWeight}
      textSize={textSize}
      margins={margins}
      icon={icon}
      timeDisplay={timeDisplay}
      delayColor={delayColor}
      style={style}
      preWrap={preWrap}
    >
      {icon && <Icon src={icon} alt="" />}
      {text}
    </Paragraph>
  );
};

Text.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  textColor: PropTypes.string,
  textWeight: PropTypes.string,
  textSize: PropTypes.string,
  margins: PropTypes.string,
  icon: PropTypes.string,
  timeDisplay: PropTypes.bool,
  delayColor: PropTypes.string,
};

Text.defaultProps = {
  textColor: primaryColorsAlpha.MainDarkColor56,
  textWeight: "",
  textSize: "",
};

export default Text;
