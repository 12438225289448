import apiBase from './api-base';

const endpoints = [
  'session',
  'club',
  'athlete',
  'seance',
  'live',
  'booking',
  'product',
  'passwordRecovery',
  'videoLibrary/checkAccess',
  'videoLibrary/search',
  'passwordRecovery',
  'liveLink'
];

// eslint-disable-next-line
const serialize = (obj) => !!obj && Object.entries(obj)
.filter(([ k, v ]) => typeof v !== 'undefined' && v !== null)
.map(([ k, v ]) => encodeURIComponent(k) + "=" + encodeURIComponent(v))
// eslint-disable-next-line
.join('&') || '';

let api = endpoints.reduce( (acc,name) => {
  acc[name] = {
    create: (body) => apiBase.POST(`/mobile/${name}`, body)[0],
    edit: (id, body) => apiBase.PATCH(`/mobile/${name}/${id}`, body)[0],
    search: (params) => apiBase.GET(`/mobile/${name}?${serialize(params)}`)[0],
    get: (id) => apiBase.GET(`/mobile/${name}/${id}`)[0],
    delete: (id, body) => apiBase.DELETE(`/mobile/${name}/${id}`, body)[0],
    cCreate: (body) => apiBase.POST(`/mobile/${name}`, body),
    cEdit: (id, body) => apiBase.PATCH(`/mobile/${name}/${id}`, body),
    cSearch: (params) => apiBase.GET(`/mobile/${name}?${serialize(params)}`),
    cGet: (id) => apiBase.GET(`/mobile/${name}/${id}`),
    cDelete: (id, body) => apiBase.DELETE(`/mobile/${name}/${id}`, body),
  };
  return acc;
}, {});

export default api;
