
function videoLinkParserYoutube(url) {
  // Accepter format link
  // https://www.youtube.com/watch?v=L3hrJ5P-pUE
  // https://youtu.be/L3hrJ5P-pUE
  let match = url.match('^https?://www\\.youtube\\.com/watch\\?v=([^#&?]+)');
  if (match) {
    return {videoId: match[1]};
  }
  match = url.match('^https?://youtu\\.be/([^#&?]+)');
  if (match) {
    return {videoId: match[1]};
  }
  return false;
}

function videoLinkParserVimeo(url) {
  // Accepter format link
  // https://vimeo.com/521814221/bee6d2681f
  // https://vimeo.com/521814221
  // https://player.vimeo.com/video/521814221
  const match = url.match('^https?://(player\\.)?vimeo\\.com/(video/)?([0-9]+)');
  if (match) {
    return {videoId: match[3]};
  }
  return false;
}

function videoLiveUrl(url) {
  if (url.match('m3u8'))
    return url;
  return false;
}

export function videoLinkParser(videoLink) {
  if (typeof videoLink !== 'string' || videoLink.length <= 0) {
    return;
  }
  const youtubeURL = videoLinkParserYoutube(videoLink);
  if (youtubeURL) {
    const youtubeVideoId = youtubeURL.videoId;
    return {
      type: 'youtube',
      videoId: youtubeVideoId,
      embedURL: `https://www.youtube.com/embed/${youtubeVideoId}/?rel=0&playsinline=1&loop=1`,
    };
  }
  const vimeoURL = videoLinkParserVimeo(videoLink);
  if (vimeoURL) {
    const vimeoVideoId = vimeoURL.videoId;
    return {
      type: 'vimeo',
      videoId: vimeoVideoId,
      embedURL: `https://player.vimeo.com/video/${vimeoVideoId}`,
    };
  }
  const liveURL = videoLiveUrl(videoLink);
  if (liveURL) {
    return {
      type: 'live',
      embedURL: liveURL,
    };
  }
  return false;
}
